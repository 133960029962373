import {
    HUB,
    TEAMS_HUB,
    WIN32_OUTLOOK_HUB,
    OWA_HUB,
    MONARCH_HUB,
    OFFICE_WEB,
    OFFICE_DESKTOP,
} from './types/HostApp';
import { getQueryStringParameter, hasQueryStringParameter } from 'owa-querystring';
import { isRunningInMetaOSHub } from './isRunningInMetaOSHub';

const validHubNames = [
    'teams', //Teams v1
    'teamsmodern', //Teams v2.1
    'outlook', //Outlook Native (Monarch) or OWA
    'outlookwin32', //Outlook Win32
    'office',
];
const validHostClientType = ['desktop', 'web'];

export let currentHub: string;
export function setHostHub(hubName: string, hostClientType: string | undefined) {
    // To smooth the transition to using context, we are respecting the hostApp param if the MetaOS app is still using it
    // if the param is removed, we will use the information from the context to deduce the hostHub
    // The removal of hostApp param is tracked by WI:169112
    const validValues = [
        HUB,
        TEAMS_HUB,
        WIN32_OUTLOOK_HUB,
        MONARCH_HUB,
        OWA_HUB,
        OFFICE_WEB,
        OFFICE_DESKTOP,
    ];
    let parameterValue;
    if (hasQueryStringParameter('hostApp')) {
        parameterValue = getQueryStringParameter('hostApp').toLowerCase();
    } else if (hasQueryStringParameter('testHostApp')) {
        parameterValue = getQueryStringParameter('testHostApp').toLowerCase();
    }

    if (parameterValue && validValues.includes(parameterValue)) {
        currentHub = parameterValue;
    }

    const isHosted = isRunningInMetaOSHub();
    if ((!currentHub || currentHub == validValues[0]) && isHosted) {
        const hubNameLowerCase = hubName?.toLowerCase() || '';
        const hostClientTypeLowerCase = hostClientType?.toLowerCase() || '';
        if (
            hubName &&
            validHubNames.includes(hubNameLowerCase) &&
            hostClientType &&
            validHostClientType.includes(hostClientTypeLowerCase)
        ) {
            if (hubNameLowerCase === 'teams' || hubNameLowerCase === 'teamsmodern') {
                // we distinguish web vs desktop via a column called ClientPlatform, to match Sydney
                currentHub = TEAMS_HUB;
            } else if (hubNameLowerCase === 'outlookwin32') {
                currentHub = WIN32_OUTLOOK_HUB;
            } else if (hubNameLowerCase === 'outlook') {
                if (hostClientTypeLowerCase === 'desktop') {
                    currentHub = MONARCH_HUB;
                } else if (hostClientTypeLowerCase === 'web') {
                    currentHub = OWA_HUB;
                }
            } else if (hubNameLowerCase === 'office') {
                if (hostClientTypeLowerCase === 'desktop') {
                    currentHub = OFFICE_DESKTOP;
                } else if (hostClientTypeLowerCase === 'web') {
                    currentHub = OFFICE_WEB;
                }
            } else {
                currentHub = HUB;
            }
        } else {
            // log hubName
            currentHub = HUB;
        }
    }
}

export function getHostHub() {
    return currentHub;
}
