import type { MailboxInfo } from 'owa-client-types';
import { default as getAccountByMailboxInfo } from './getAccountByMailboxInfo';

/**
 * Returns the sourceId associated with the specified mailbox info
 * @param mailboxInfo to be used to find the account source
 * @returns sourceId associated with the mailbox info, or undefined if the mailbox info
 *          does not match with an entry in the source list.
 */
export default function getSourceIdByMailboxInfo(mailboxInfo: MailboxInfo): string | undefined {
    return getAccountByMailboxInfo(mailboxInfo)?.sourceId;
}
