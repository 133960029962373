import { isBootFeatureEnabled } from 'owa-metatags/lib/isBootFeatureEnabled';

export function isHardwareConcurrencySupported(): boolean {
    return (
        // Safari doesn't support navigator.hardwareConcurrency
        typeof self.navigator?.hardwareConcurrency !== 'number' ||
        // 2 or less cores won't parallelize well
        self.navigator.hardwareConcurrency > 2 ||
        !isBootFeatureEnabled('disableConcurrency')
    );
}
