import findMetatag from './findMetatag';

// Boot features
//    'acct-disableUserIdentityCheck' -- disable the logging of userIdentities that are not in the account source list store
//    `acct-reflowAndDefaultTelemetry` -- Indicates that the telemetry for reflow methods that should not be called is enabled
//    `acctErrStorageSetV2` -- When enabled the owa-account-storage package will throw an exception if invalid data is set
//    `acctNoFallback` -- Disables the fallback to global settings account in place of the selected account
//    `acctPersistentIdIndexer` -- when enabled, the indexer value comes from the persistence id of the account
//    `acctManualAccountType` -- when enabled, allows the manual selected of the type of account being added
//    `acctStartDataV25` - Updated version of `acctStartDataV2` that fixes exception handling where a non Error object is thrown
//    `acctStartDataOWA` - Boot flight for rolling out the acctStartDataV2* feature to OWA
//    `fwk-consoleErrors` -- Surface console errors by showing a dialog
//    `fwk-cssPerf` -- Enables an alternate set of CSS rules that should be more performant during style recalculation
//    `fwk-skipnavbardataonhosted` -- Enable skipping navbar data call on hosted scenarios
//    `supportonboot` -- launches the support flow if boot fails
//    `appservicequery` -- when enabled, fetch metaOs apps from app service api
//    `accountsapi` -- when enabled the Monarch multi-account UX functionality is enabled in non monarch applications
//    `offlineWeb` -- enables offline in the browser, rather than just in monarch
//    `dev-bootOfflineFolders` -- enables offline folder resolvers
//    `dev-bootOffline` -- boot as if offline disabling service requests until user re-enables in diagnostics
//    `monPromptOnSilentFailure` -- enables NativeHost to fail on getTokenSilently and call getTokenInteractively when interaction is required from user
//    `mobxproxies` -- configures MobX to use proxies: https://mobx.js.org/configuration.html#proxy-support
//    `shellmultiorigin` -- Enable EUDB vs. WWDB domain branching for OPX shell.
//    `auth-msaljs-consumer` -- Enables the use of MSAL to get auth token for consumer accounts
//    `detectcmpfnleaks` -- Enables wrapper around computedFn to detect leaks
//    `disablecmpfn` -- Disables wrapping functions in computedFn to measure the impact
//    `jsselfprofiler` -- This flag lets us know when the JS self profiler is enabled
//    `acct-pstFileInfra` -- Enables PST file handling by the account source list store and related account infra
//    `bookwithme-c2B2MigrationAnonymous` -- Enables the anonymous user flow for C2B2 migration
//    `auth-msaljs-anonymousFlow` -- Enables anonymous MSALjs flows to accommodate FindTime, BookWithMe & Bookings
//    `acct-add-account-e1-improvement` -- Enable to check for consumer license and E3 or above when add an E1 account
//    `auth-cacheTokenForMetaOsHub` -- Enable token caching for MetaOS integrations. Token caching involves decoding of bearer token to extract expiry & PUID
//    `auth-useAuthTokenClaimsForMetaOsHub` -- Enable use of claims in the token to be used for auth for MetaOS integrations.
//    `cal-widgets-upn-validation` -- Enables UPN validation for widgets login, and prevents the creation of the OPX frame with invalid routingHints
//    `auth-cloudCache-basicAuthPasswordLookup` -- Attempt to get the password for a cloud cache account using basic auth (IMAP/POP, etc.)
//    `acctpopCCInOobe` -- when enabled, we're allowing Pop accounts to be added through OOBE (i.e: migration, as first acct..)
//    `auth-matsScenarios-NewAccount` -- Enables passing information for the Microsoft Authentication Telemetry System about new account creation
//    `auth-msaljs-business` -- Enables full client side authentication for business account using MSAL.js library.
//    `acct-mbxpkg-boot` -- used to control the usage of the owa-mailbox-package within the application
export type BootFeature =
    | 'acct-disableUserIdentityCheck'
    | 'acct-reflowAndDefaultTelemetry'
    | 'acctErrStorageSetV2'
    | 'acctNoFallback'
    | 'acctPersistentIdIndexer'
    | 'acctManualAccountType'
    | 'acctStartDataV25'
    | 'acctStartDataOWA'
    | 'fwk-consoleErrors'
    | 'fwk-cssPerf'
    | 'fwk-skipnavbardataonhosted'
    | 'supportonboot'
    | 'appservicequery'
    | 'pieUpdateIdentityAliasesMap'
    | 'accountsapi'
    | 'offlineWeb'
    | 'dev-bootOfflineFolders'
    | 'dev-bootOffline'
    | 'dev-offlineMultiAccountDB'
    | 'monPromptOnSilentFailure'
    | 'mobxproxies'
    | 'shellmultiorigin'
    | 'localStorageOwaPrefix'
    | 'emergencyDisableBpos'
    | 'auth-msaljs-consumer'
    | 'detectcmpfnleaks'
    | 'disablecmpfn'
    | 'jsselfprofiler'
    | 'disableOfflineDataExtractionNetworkDelay'
    | 'acct-pstFileInfra'
    | 'bookwithme-c2B2MigrationAnonymous'
    | 'auth-msaljs-anonymousFlow'
    | 'acct-add-account-e1-improvement'
    | 'auth-cacheTokenForMetaOsHub'
    | 'auth-useAuthTokenClaimsForMetaOsHub'
    | 'cal-widgets-upn-validation'
    | 'auth-cloudCache-basicAuthPasswordLookup'
    | 'acctpopCCInOobe'
    | 'auth-matsScenarios-NewAccount'
    | 'auth-msaljs-business'
    | 'acct-mbxpkg-boot'
    | 'disableConcurrency'
    | 'workerAsyncLoad';
// these are specifically for features that need to be decided before userbootsettings comes back.
let bootFlights: string[] | undefined;
let bootFlightsLowercase: Set<string> | undefined;
let bootFlightsWithAllocationIds: string[] | undefined;
export function isBootFeatureEnabled(feature: BootFeature) {
    return getBootFlightsLowercase().has(feature.toLowerCase());
}

export function getBootFlights(): string[] {
    if (!bootFlights) {
        const meta = findMetatag('bootFlights');
        bootFlights = meta ? meta.split(',').map(m => m.split(':')[0]) : [];
    }
    return bootFlights;
}

export function getBootFlightsWithAllocationIds(): string[] {
    if (!bootFlightsWithAllocationIds) {
        const meta = findMetatag('bootFlights');

        bootFlightsWithAllocationIds = meta ? meta.split(',') : [];
    }
    return bootFlightsWithAllocationIds;
}

export function test_reset() {
    bootFlights = undefined;
    bootFlightsLowercase = undefined;
}

function getBootFlightsLowercase(): Set<string> {
    if (!bootFlightsLowercase) {
        bootFlightsLowercase = new Set(getBootFlights().map(key => key.toLowerCase()));
    }
    return bootFlightsLowercase;
}
