import throttledFetch from './throttledFetch';
import fetchHandler from './fetchHandler';
import isRetriableStatus from './isRetriableStatus';
import checkAndLogMailboxInfo from './checkAndLogMailboxInfo';
import addFetchRetryHeaders from './addFetchRetryHeaders';
import type RequestOptions from './RequestOptions';
import type MailboxRequestOptions from './MailboxRequestOptions';
import type { ServiceResponseCallback } from 'owa-analytics-types/lib/types/ServiceResponseCallback';
import getRequestNumber from './getRequestNumber';
import createOptionsForServiceRequest from './createOptionsForServiceRequest';
import isRetriableAuthError from './isRetriableAuthError';
import { AuthorizationHeaderName, WebSessionTypeHeaderName } from './createDefaultHeader';
import sleep from 'owa-sleep';
import { getConfig } from './config';
import isAuthRetriableForRequest from './isAuthRetriableForRequest';
import { addTimingsToNetworkRequest } from 'owa-analytics-shared';
import type { TraceErrorObject } from 'owa-trace';
import { resetOwaCanaryCookie } from './canary';

const DEFAULT_MAX_ATTEMPT: number = 2;
const TIMEOUT_ON_DISCONNECT = 5000;

let serviceResponseCallbackNumber = 0;
const createServiceResponseCallbacks: {
    [key: number]: ServiceResponseCallback;
} = {};

// this returns a function that will unregister it
export function registerCreateServiceResponseCallback(
    callback: ServiceResponseCallback
): () => void {
    const id = serviceResponseCallbackNumber++;
    createServiceResponseCallbacks[id] = callback;

    return () => {
        delete createServiceResponseCallbacks[id];
    };
}

export default function fetchWithRetry(
    actionName: string,
    originalUrl: string,
    attemptCount: number,
    requestOptions: RequestOptions | MailboxRequestOptions | undefined,
    parameters?: any
): Promise<any> {
    if (requestOptions?.perfDatapoint) {
        const dp = requestOptions.perfDatapoint;
        const datapointIsDefined = dp.datapoint || dp.customDatapoint;

        if (datapointIsDefined) {
            const settings = {
                discardIfDefined: dp.discardIfDefined || false,
            };

            return addTimingsToNetworkRequest(
                {
                    datapoint: dp.datapoint,
                    customDatapoint: dp.customDatapoint,
                },
                settings,
                fetchWithRetryFunction,
                actionName,
                originalUrl,
                attemptCount,
                requestOptions,
                parameters
            );
        }
    }
    return fetchWithRetryFunction(
        actionName,
        originalUrl,
        attemptCount,
        requestOptions,
        parameters
    );
}

async function fetchWithRetryFunction(
    actionName: string,
    originalUrl: string,
    attemptCount: number,
    requestOptions: RequestOptions | MailboxRequestOptions | undefined,
    parameters?: any
): Promise<any> {
    const optionsPromise = createOptionsForServiceRequest(requestOptions, parameters, actionName);
    const url = originalUrl + '&n=' + getRequestNumber();
    const promise = throttledFetch(url, optionsPromise);
    let logActionName = actionName;

    // Use the Update Field to indicate the actual UpdateItem action for this OWS service request.
    if (actionName == 'UpdateItem') {
        logActionName =
            actionName + parameters?.Body?.ItemChanges?.[0]?.Updates?.[0]?.Path?.FieldURI || '';
    }

    for (const callback of Object.values(createServiceResponseCallbacks)) {
        callback(promise, logActionName, url, attemptCount, optionsPromise);
    }

    return optionsPromise.then(options => {
        // We would like to know where this function was invoked from so let's create the callstack here
        // and pass it through just in case we need it.
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        const callstackAtRequest = new Error(actionName + 'RequestFailed').stack;
        const { retryCount: maxRetryCount = DEFAULT_MAX_ATTEMPT } = options;
        return promise.then(
            async function (response: Response) {
                let shouldRetry: boolean = false;
                let endpoint: string = originalUrl;
                const status = response.status;
                if (
                    (!!options?.shouldRetry
                        ? true === (await options.shouldRetry(status))
                        : isRetriableStatus(status)) &&
                    attemptCount < maxRetryCount
                ) {
                    if (options?.onBeforeRetry) {
                        const retryOptions = await options.onBeforeRetry(response);

                        // if we have retryOptions, then let's try to retry again
                        if (retryOptions) {
                            shouldRetry = true;
                            if (retryOptions.delay) {
                                await sleep(retryOptions.delay);
                            }
                            if (retryOptions.endpoint) {
                                endpoint = retryOptions.endpoint;
                            }
                        }
                    } else {
                        shouldRetry = true;
                    }
                }

                if (shouldRetry) {
                    // if the error is a retriable auth error, then let's try to get a new auth token
                    if (isRetriableAuthError(status) && isAuthRetriableForRequest(options)) {
                        const config = getConfig();
                        checkAndLogMailboxInfo(
                            config,
                            'Acct-FetchWithRetryV2MailboxInfo',
                            requestOptions?.mailboxInfo
                        );

                        // we should clear the auth token since we know it failed to authenticate
                        config.onAuthFailed?.(response.headers, requestOptions?.mailboxInfo);

                        // lets try to get a new auth token
                        const authToken = await config.getAuthToken?.(
                            response.headers,
                            requestOptions?.mailboxInfo
                        );

                        if (authToken) {
                            options.headers.set(AuthorizationHeaderName, authToken);

                            if (config.getWebSessionType) {
                                const webSessionType = await config.getWebSessionType();
                                if (webSessionType) {
                                    options.headers.set(WebSessionTypeHeaderName, webSessionType);
                                }
                            }
                        } else if (options.headers.has(AuthorizationHeaderName)) {
                            options.headers.delete(AuthorizationHeaderName);
                        }
                    }

                    // on all retries, lets clear the canary to be safe
                    resetOwaCanaryCookie();
                    addFetchRetryHeaders(++attemptCount, options.headers);
                    return fetchWithRetry(actionName, endpoint, attemptCount, options, parameters);
                }
                return fetchHandler<any>(actionName, response, options, callstackAtRequest);
            },
            function (error: TraceErrorObject) {
                if (error.retriable && attemptCount < maxRetryCount) {
                    return new Promise((resolve, reject) => {
                        setTimeout(async () => {
                            try {
                                addFetchRetryHeaders(++attemptCount, options.headers);
                                resolve(
                                    await fetchWithRetry(
                                        actionName,
                                        originalUrl,
                                        attemptCount,
                                        options,
                                        parameters
                                    )
                                );
                            } catch (err: any) {
                                const e = err as TraceErrorObject;
                                if (e.message) {
                                    try {
                                        Object.defineProperty(e, 'message', {
                                            value: actionName + ':' + e.message,
                                        });
                                    } catch {
                                        // no op if we can edit the message
                                    }
                                }
                                reject(e);
                            }
                        }, TIMEOUT_ON_DISCONNECT);
                    });
                } else {
                    error.networkError = true;
                    throw error;
                }
            }
        );
    });
}
