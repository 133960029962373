import { getItem } from 'owa-local-storage';

const EXCHANGE_ONLINE_APP_ID = '00000002-0000-0ff1-ce00-000000000000';
let owaAppId: string | null = null;

export default function getOwaAppId() {
    if (!owaAppId) {
        // try to get the app id from msal account object
        const appIdFromLocalStorage = getItem(self, 'OWAAppId');
        if (appIdFromLocalStorage) {
            owaAppId = appIdFromLocalStorage;
        } else {
            owaAppId = EXCHANGE_ONLINE_APP_ID;
        }
    }

    return owaAppId;
}
