/**
 * Returns a promise that would wait for a given time before resolving or rejecting
 *
 * @param time time in milliseconds to delay fulfillment
 * @param value
 * @param shouldReject whether the promise should reject or resolve
 */
export default function sleep<T = undefined>(
    time: number,
    value?: T | Error,
    shouldReject?: boolean
) {
    // prevent unhandled rejections for promise values
    if (Promise.resolve(value) === value) {
        // since we're creating a separate delayed promise
        // any unhandled rejection should only come from that promise
        // and not the original one.
        (value as Promise<any>).catch(() => {});
    }
    return new Promise<Awaited<T>>((resolve, reject) => {
        setTimeout(shouldReject ? reject : resolve, time, value);
    });
}
