import getSessionData from './getSessionData';
import { onBootComplete } from './onBootComplete';
import { onBootError } from './onBootError';
import { setApp } from 'owa-config/lib/bootstrapOptions';
import createBootError from './createBootError';
import { markEnd, markStart, markFunction, trackBottleneck } from 'owa-performance';
import type StartConfig from './interfaces/StartConfig';
import { setAriaTenantToken } from './ariaUtils';
import { getOwsPath, getOpxHostData } from 'owa-config';
import getScopedPath from 'owa-url/lib/getScopedPath';
import { updateServiceConfig } from 'owa-service/lib/config';
import { setIsDeepLink } from 'owa-url/lib/isDeepLink';
import type { PromiseWithKey } from 'owa-performance';
import { unblockLazyLoadCallbacks } from 'owa-bundling-light';
import { hasQueryStringParameter } from 'owa-querystring';
import { setBootFailureCount } from './bootErrorCounter';
import { setThreadName } from 'owa-thread-config';
import type { MailboxInfo } from 'owa-client-types';
import {
    lazyGetAnchorMailboxProxy,
    lazyGetAuthTokenForBootFromMsalProxy,
    lazyMsalImportProxy,
} from 'owa-msaljs/lib/lazyAppBoot';
import type { HeadersWithoutIterator } from 'owa-service/lib/RequestOptions';
import { initializeCssInjection } from 'owa-inject-css';
import { preloadFonts } from './preloadFonts';
import { pwOnBootComplete } from 'owa-playwright';
import { isMonitoringProbe } from 'owa-msaljs/lib/isMonitoringProbe';
import { isMsalFlowEnabled } from 'owa-msaljs/lib/isMsalFlowEnabled';
import { yieldNow } from 'owa-task-queue/lib/schedule';

let startTime: number;

export function sharedStart(config: StartConfig): Promise<any> {
    return internalStart(config)
        .then(() => {
            try {
                onBootComplete(config, startTime);

                // we still want to call on loader removed
                // if there was no loader at all
                config.onLoaderRemoved?.();
                unblockLazyLoadCallbacks();
                setBootFailureCount(0);

                // call Playwright
                if (isMonitoringProbe()) {
                    pwOnBootComplete();
                }
            } catch (e) {
                throw createBootError(e, 'BootComplete');
            }
        })
        .catch(bootError => onBootError(bootError, config));
}

async function internalStart(config: StartConfig) {
    try {
        startTime = Date.now();
        if (config.applicationId) {
            updateServiceConfig({ applicationId: config.applicationId });
        }

        // Set the thread name for the current thread
        setThreadName('MAIN_THREAD');
        setApp(config.app);
        setIsDeepLink(!!config.isDeepLink);
        setAriaTenantToken(config.startupAriaToken);

        // NOTE: This is for TESTING ONLY.
        if (process.env.NODE_ENV !== 'production' && hasQueryStringParameter('testsupportonboot')) {
            throw new Error('Test fail');
        }

        // Initialize CSS injection
        initializeCssInjection();

        updateServiceConfig({
            baseUrl: getScopedPath(getOwsPath()),
        });

        if (isMsalFlowEnabled()) {
            // Load and initialize MSAL in order to be able to send startupdata request
            // before we start evaluating other boot bundles
            await lazyMsalImportProxy.importAndExecute('MsalBoot');

            updateServiceConfig({
                getAuthToken: (headers?: HeadersWithoutIterator, mailboxInfo?: MailboxInfo) =>
                    lazyGetAuthTokenForBootFromMsalProxy.importAndExecute(headers, mailboxInfo),
                getAnchorMailbox: (mailboxInfo?: MailboxInfo) =>
                    lazyGetAnchorMailboxProxy.importAndExecute(mailboxInfo),
            });
        }

        if (config.runBeforeStart) {
            markStart('rbsp');
            await config.runBeforeStart(config).catch(error => {
                if (!error.source) {
                    error.source = 'BeforeBoot';
                }
                throw error;
            });
            markEnd('rbsp');
        }

        const sessionDataPromise = config.overrideBootPromises?.() ?? getSessionData();

        if (config.overrideBootPromises) {
            // make sure sessiondata can send network calls and trigger postMessage's if needed
            await yieldNow();
        }

        const javascriptPromise = markFunction(config.bootstrap.import, 'mjs')();
        const fontPromise = preloadFonts();

        const bootPromises: PromiseWithKey<any>[] = [
            { promise: sessionDataPromise, key: 'sd' },
            { promise: javascriptPromise, key: 'js' },
            { promise: fontPromise, key: 'fonts' },
        ];

        const opxHostPromise = getOpxHostData();
        const bootstrapPromises: Promise<any>[] = [javascriptPromise];
        if (opxHostPromise) {
            bootstrapPromises.push(opxHostPromise);
        }

        const strategies = config.strategies;
        bootPromises.push({
            promise: Promise.all(bootstrapPromises)
                .then(([bootstrap]) => {
                    try {
                        return bootstrap(sessionDataPromise, strategies);
                    } catch (e) {
                        throw createBootError(e, 'Bootstrap');
                    }
                })
                .catch(e => {
                    throw createBootError(e, 'Script');
                }),
            key: null,
        });

        if (config.runAfterRequests) {
            // make sure boot promises can send network calls
            await yieldNow();
            config.runAfterRequests(sessionDataPromise);
        }

        if (strategies) {
            // start downloading the boot strategies right away
            for (const lazyAction of Object.values(strategies)) {
                if (lazyAction) {
                    lazyAction.import();
                }
            }
        }

        return trackBottleneck('start', bootPromises);
    } catch (e) {
        return Promise.reject(createBootError(e, 'Preboot'));
    }
}
