import { type HttpStatusCode } from 'owa-http-status-codes';
import { getConfig } from './config';

export default function isRetriableAuthError(
    status: number,
    authNeededOnUnAuthorized?: boolean
): boolean {
    return (
        (!!getConfig().getAuthToken || !!authNeededOnUnAuthorized) &&
        (status == 401 || status == 440)
    );
}
