import type { CustomDataMap } from 'owa-analytics-types';

type BootCustomDataMap = {
    SessionDataNetworkRace: CustomDataMap;
    HubLoadData: {
        userClickTime: number;
    };
    MetaOsAppLoaded: number;
};

type BootCustomDataMapKeys = keyof BootCustomDataMap;
const bootCustomData: Partial<BootCustomDataMap> = {};

export function addBootCustomData<K extends BootCustomDataMapKeys>(
    key: K,
    data: BootCustomDataMap[K]
): void {
    bootCustomData[key] = data;
}

export function getBootCustomData(): Partial<BootCustomDataMap> {
    return bootCustomData;
}
