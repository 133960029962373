import { markFunction } from 'owa-performance';
import { LazyBootModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "AppBootMetaOs" */ './lazyFunctions')
);

export const lazyInitializeMetaOsAppSdkProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyInitializeMetaOsAppSdk;
    return markFunction(
        action.importAndExecute.bind(action),
        'MetaOSPlatformAppBootImportAndExecuteScript'
    );
});

export const lazyGetOwaAuthTokenFromMetaOsHubProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyGetOwaAuthTokenFromMetaOsHub;
    return action.importAndExecute.bind(action);
});

export const lazyInitializeMetaOsCapabilitiesProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyInitializeMetaOsCapabilities;
    return action.importAndExecute.bind(action);
});

export const lazyGetContextProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyGetContext;
    return action.importAndExecute.bind(action);
});

export const lazyRegisterOnLoadHandlerProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyRegisterOnLoadHandler;
    return action.importAndExecute.bind(action);
});

export const lazyRegisterBeforeUnloadHandlerProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyRegisterBeforeUnloadHandler;
    return action.importAndExecute.bind(action);
});

export const lazyGetOwaDecodedAuthTokenFromMetaOsHubProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyGetOwaDecodedAuthTokenFromMetaOsHub;
    return action.importAndExecute.bind(action);
});

export const lazyPreInitOwaAccountSourceListStoreProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyPreInitOwaAccountSourceListStore;
    return action.importAndExecute.bind(action);
});

export const lazyGetAccountServiceConfigProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyGetAccountServiceConfig;
    return action.importAndExecute.bind(action);
});

export const lazyNotifyAppLoadedProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyNotifyAppLoaded;
    return action.importAndExecute.bind(action);
});
