import { getOriginWithTrailingSlash } from 'owa-url/lib/getOrigin';
import { isBootFeatureEnabled } from 'owa-metatags/lib/isBootFeatureEnabled';
import findMetatag from 'owa-metatags/lib/findMetatag';
import { OWA_RESOURCE_URL_ENTERPRISE } from './utils/constants';

/**
 * Checks if the request is enabled for business accounts
 **/
export function isMsalEnabledForBusiness(): boolean {
    if (!isBootFeatureEnabled('auth-msaljs-business')) {
        return false;
    }

    // get the origin of the request with trailing slash to compare against the OWA_RESOURCE_URL_ENTERPRISE urls.
    const origin = getOriginWithTrailingSlash();

    // If getOrigin is one of OWA public urls, then return true.
    if (OWA_RESOURCE_URL_ENTERPRISE.includes(origin)) {
        return true;
    }

    // For business accounts we have different vanity urls and soverign cloud urls.
    const businessCanonicalHostName = findMetatag('businessCanonicalHostName');
    const originUrl = new URL(origin);

    // If host name is equal to businessCanonicalHostName(vanity urls and sovereign cloud urls), then return true.
    return (
        businessCanonicalHostName != undefined && businessCanonicalHostName == originUrl.hostname
    );
}
