import { isBootFeatureEnabled } from 'owa-metatags/lib/isBootFeatureEnabled';
import { getOrigin } from 'owa-url/lib/getOrigin';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import { getHostLocation } from 'owa-url/lib/hostLocation';
import getRootVdirName from 'owa-url/lib/getRootVdirName';

/**
 * Checks if the request is for Consumer account and whether token fetch from MSAL is enabled.
 **/
export function isMsalEnabledForConsumerInZeroAccount(): boolean {
    return (
        areMsalConsumerFlightsEnabled() &&
        getOrigin().toLowerCase().includes('live') &&
        !isIndexedPathForShadowMailbox()
    );
}

export function areMsalConsumerFlightsEnabled() {
    return (
        isBootFeatureEnabled('auth-msaljs-consumer') && isHostAppFeatureEnabled('msalTokenFetch')
    );
}

// Returns true if url specified is an indexed path (e.g. /mail/1/) for accessing connected Shawdow Mailbox (Gmail)
export function isIndexedPathForShadowMailbox(): boolean {
    const path = getHostLocation()?.pathname;
    if (path) {
        const postVdirPath = path.split('/' + getRootVdirName() + '/')[1];
        if (postVdirPath) {
            const postVdirSegment = postVdirPath.split('/')[0];
            if (postVdirSegment && parseInt(postVdirSegment) == 1) {
                return true;
            }
        }
    }
    return false;
}
