// The hosting application may need to provide external data, the ExternalDataType enumeration
// is used to identify the type of external data associated with the account
export enum ExternalDataType {
    None,
    NativeHost,
}

// External data associated with accounts will be stored in a structure derived from
// this type
export interface ExternalData {
    type: ExternalDataType;
}
