import { OwaApplicationId } from './constants/ApplicationId';

import type TokenResponse from './contract/TokenResponse';
import type { MailboxInfo } from 'owa-client-types';
import type { HeadersWithoutIterator, RequestOptions } from './RequestOptions';
import type MailboxRequestOptions from './MailboxRequestOptions';
export interface MetaOSContext {
    locale: string;
    hostName: string;
    clientType?: string;
}

export interface ServiceRequestConfig {
    applicationId: string;
    getMsalToken?: (
        isConsumer: boolean,
        resource?: string,
        mailboxInfo?: MailboxInfo,
        scope?: string
    ) => Promise<TokenResponse | undefined>;
    getAuthToken?: (
        headers?: HeadersWithoutIterator,
        mailboxInfo?: MailboxInfo
    ) => Promise<string | undefined>;
    getResourceToken?: (
        resource: string,
        wwwAuthenticateHeader?: string,
        mailboxInfo?: MailboxInfo,
        scope?: string,
        childAppId?: string,
        allowPrompt?: boolean,
        claims?: string
    ) => Promise<TokenResponse | undefined>;
    getUserToken?: (
        headers?: HeadersWithoutIterator,
        mailboxInfo?: MailboxInfo
    ) => Promise<string | undefined>;
    getWebSessionType?: (mailboxInfo?: MailboxInfo) => Promise<string>;
    onAuthFailed?: (headers?: HeadersWithoutIterator, mailboxInfo?: MailboxInfo) => void;
    baseUrl: string;
    timezone?: string;
    isFeatureEnabled: (feature: string, mailboxInfo?: MailboxInfo) => boolean;
    isUserIdle?: () => boolean;
    prepareRequestOptions?: (
        options: RequestOptions | MailboxRequestOptions | undefined
    ) =>
        | RequestOptions
        | MailboxRequestOptions
        | null
        | undefined
        | Promise<RequestOptions | MailboxRequestOptions>;
    timeoutMS?: number;
    disableAllRequests?: boolean;
    getAnchorMailbox?: (mailboxInfo?: MailboxInfo) => Promise<string | undefined>;
    getExplicitLogon?: (mailboxInfo?: MailboxInfo) => Promise<string | undefined>;
    isResourceTokenPrefetchEnabled?: boolean;
    metaOSContext?: MetaOSContext;
    getGlobalSettingsAccountMailboxInfo?: () => MailboxInfo | undefined;
    getGlobalSettingsAccountPersistenceId?: () => string | undefined;
    getCanaryHeaders?: () => Record<string, string>;
    executeNaaRequest?: (
        mailboxInfo: MailboxInfo,
        request: string,
        origin: string,
        scenarioName: string,
        callerId: string
    ) => Promise<string>;
}

const defaultCachedConfig = {
    baseUrl: '/owa',
    isFeatureEnabled: () => false,
    timeoutMS: 90000,
    disableAllRequests: false,
    isResourceTokenPrefetchEnabled: false,
    applicationId: OwaApplicationId,
};

let cachedConfig: ServiceRequestConfig = defaultCachedConfig;

export function updateServiceConfig(config?: Partial<ServiceRequestConfig>) {
    cachedConfig = {
        ...cachedConfig,
        ...config,
    };
}

export function getConfig(): ServiceRequestConfig {
    return cachedConfig;
}

export function resetDefault() {
    cachedConfig = defaultCachedConfig;
}
