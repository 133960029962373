import type { MetatagName } from 'owa-metatags';
import { findMetatag } from 'owa-metatags';
import { hasQueryStringParameter } from 'owa-querystring';
import { getWindowData } from 'owa-window-data';
import { getDefaultCdnSettings, getDefaultCdnUrl } from './defaultCdnSettings';

const callbacks: Array<(app: string) => void> = [];
export function registerAppUpdate(callback: (app: string) => void) {
    callbacks.push(callback);
}

function sendAppUpdate(app: string) {
    for (const callback of callbacks) {
        callback(app);
    }
}

let overrideApp: string;
export function setApp(app: string) {
    overrideApp = app;
    sendAppUpdate(app);
}

let overrideBaseUrl: string;
export function setOverrideBaseUrls(baseUrl: string) {
    overrideBaseUrl = baseUrl;
}

export function getApp() {
    return overrideApp;
}

export function getClientVersion() {
    return getMetatagValue('scriptVer');
}

export function getCdnUrl(): string {
    const url = getMetatagValue('cdnUrl');
    if (isUrlPresent(url)) {
        return url;
    }

    return getDefaultCdnUrl();
}

export function getBackupCdnUrl(): string {
    const url = getMetatagValue('backupCdnUrl');
    if (isUrlPresent(url)) {
        return url;
    }

    return getDefaultCdnUrl(true);
}

type Maybe<T> = T | undefined;

let isGulpOrBranchingValue: Maybe<boolean>;
export function isGulpOrBranching(): boolean {
    return (isGulpOrBranchingValue ??= isUrlPresent(getMetatagValue('devCdnUrl')));
}

let isGulpingValue: Maybe<boolean>;
export function isGulping(): boolean {
    // we are gulping if the devCdnUrl is present and the container name doesn't end with -branch
    return (isGulpingValue ??=
        !!getMetatagValue('devCdnUrl') &&
        !new URL(addProtocol(getMetatagValue('devCdnUrl'))).pathname
            ?.split('/')
            .filter(p => p)[0]
            ?.endsWith('-branch'));
}

export function getDevCdnUrl(): string | undefined {
    const url = getMetatagValue('devCdnUrl');
    if (isUrlPresent(url)) {
        return url;
    }

    return undefined;
}

export function getPackageBaseUrl(forceVersionPath?: boolean, useBackupCdn?: boolean): string {
    if (overrideBaseUrl) {
        return overrideBaseUrl;
    }

    const devCdnUrl = getDevCdnUrl();
    if (devCdnUrl) {
        return devCdnUrl;
    }

    const cdnUrl = getMetatagValue(useBackupCdn ? 'backupCdnUrl' : 'cdnUrl');
    const cdnContainer = getMetatagValue('cdnContainer');

    if (isUrlPresent(cdnUrl) && isUrlPresent(cdnContainer)) {
        const maybeHashedPath = forceVersionPath ? undefined : getMetatagValue('hashedPath');
        return cdnUrl + cdnContainer + (maybeHashedPath || getClientVersion()) + '/';
    }

    // Make sure the meta tag in the index page is getting replaced by owa-web-server.
    // If it is not, then we will use the hard coded value
    const defaultCdnSettings = getDefaultCdnSettings();
    return addVersionToPath(
        useBackupCdn ? defaultCdnSettings.BackupBaseUrl : defaultCdnSettings.PackageBaseUrl
    );
}

const scriptPathValue: [Maybe<string>, Maybe<string>] = [undefined, undefined];
/**
 * Most scripts are processed by webpack and therefore have a hashed path.
 * If your scripts are just copied by webpack, you will need to use `true`
 */
export function getScriptPath(forceVersionPath?: boolean): string {
    return (scriptPathValue[forceVersionPath ? 0 : 1] ??= addProtocol(
        getPackageBaseUrl(forceVersionPath) + getScriptRelativePath()
    ));
}

let resourcePathValue: Maybe<string> = undefined;
/**
 * Resources that are loaded manually are not processed by webpack and therefore
 * do not have a hashed path.
 */
export function getResourcePath(): string {
    return (resourcePathValue ??= addProtocol(
        getPackageBaseUrl(true /* forceVersionPath */) + getResourceRelativePath()
    ));
}

export function getScriptBackupPath(forceVersionPath?: boolean): string {
    return addProtocol(
        getPackageBaseUrl(forceVersionPath, true /* userBackupCdn */) + getScriptRelativePath()
    );
}

export function isUrlPresent(url: string): boolean {
    return !!url && url.indexOf('/') > -1;
}

function getScriptRelativePath() {
    const scriptPath = getMetatagValue('scriptPath');
    return isUrlPresent(scriptPath) ? scriptPath : getDefaultCdnSettings().ScriptPath;
}

function getResourceRelativePath() {
    return getExperimentPath() + 'resources/';
}

let lazyProtocol: string;
const protocolFn = () => {
    if (lazyProtocol === undefined) {
        lazyProtocol = getWindowData().location?.protocol || 'https:';
    }

    return lazyProtocol;
};

const protocolRegExp = new RegExp('^https?:');
export function addProtocol(url: string) {
    // Only add the protocol if we don't already have one
    if (!protocolRegExp.test(url)) {
        url = protocolFn() + url;
    }

    return url;
}

function getMetatagValue(tag: MetatagName): string {
    return findMetatag(tag) || '';
}

function addVersionToPath(base: string): string {
    const version = isGulpOrBranching()
        ? ''
        : getClientVersion() + '/' + (hasQueryStringParameter('debugJs') ? 'debug/' : '');
    return base + version;
}

function getExperimentPath() {
    const scriptPath = getMetatagValue('scriptPath');
    if (isUrlPresent(scriptPath) && scriptPath != getDefaultCdnSettings().ScriptPath) {
        const experiment = scriptPath.split('/')[0];
        return experiment ? experiment + '/' : '';
    }
    return '';
}
