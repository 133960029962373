import { LazyAction, LazyBootModule } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "AppBootDataWorker" */ './lazyFunctions'),
    { name: 'AppBootDataWorker' }
);

// precompile schema intentionally exported non-lazy to ensure it's included in the main bundle and can kick off as early as possible
export { precompileSchema } from './actions/precompileSchema';
export { initializeWorker, getOfflineSessionData } from './actions/getOfflineSessionData';
export { initializeOfflineTimezoneData } from './actions/getOfflineTimeZones';

export const lazyExecuteRequest = new LazyAction(lazyModule, m => {
    const action = m.lazyExecuteRequest;
    return action.importAndExecute.bind(action);
});
export const lazyUnsubscribe = new LazyAction(lazyModule, m => {
    const action = m.lazyUnsubscribe;
    return action.importAndExecute.bind(action);
});
export const lazySetDisableAllRequests = new LazyAction(lazyModule, m => {
    const action = m.lazySetDisableAllRequests;
    return action.importAndExecute.bind(action);
});
export const lazyFlushSyncLogs = new LazyAction(lazyModule, m => {
    const action = m.lazyFlushSyncLogs;
    return action.importAndExecute.bind(action);
});
export const lazyOnTableReloaded = new LazyAction(lazyModule, m => {
    const action = m.lazyOnTableReloaded;
    return action.importAndExecute.bind(action);
});
export const lazyOnManualSyncMail = new LazyAction(lazyModule, m => {
    const action = m.lazyOnManualSyncMail;
    return action.importAndExecute.bind(action);
});
export const lazySaveOfflineSessionData = new LazyAction(lazyModule, m => {
    const action = m.lazySaveOfflineSessionData;
    return action.importAndExecute.bind(action);
});
export const lazyGetOfflineTimeZones = new LazyAction(lazyModule, m => {
    const action = m.lazyGetOfflineTimeZones;
    return action.importAndExecute.bind(action);
});
export const lazyGetOfflineTimeZoneOffsets = new LazyAction(lazyModule, m => {
    const action = m.lazyGetOfflineTimeZoneOffsets;
    return action.importAndExecute.bind(action);
});
export const lazyScheduleHierarchySync = new LazyAction(lazyModule, m => {
    const action = m.lazyScheduleHierarchySync;
    return action.importAndExecute.bind(action);
});
