import { getQueryStringParameter } from 'owa-querystring';
import { postSignal } from './ariaUtils';
import { createBootReliabilityAriaEvent } from './createBootReliabilityAriaEvent';
import type StartConfig from './interfaces/StartConfig';
import { resolvePendingDatapoints, addPASKeyMetric } from 'owa-performance';

const performanceMarkQueryStringParam = 'pm';
const perfMarkName = 'plt';

export function onBootComplete(config: StartConfig, start?: number) {
    if (config.skipStartupSignal) {
        return;
    }
    const timings = window.performance?.now && {
        start,
        plt: config.wasUserPrompted ? undefined : Math.floor(window.performance.now()),
        upt: config.userPromptTime,
    };

    if (timings?.plt && timings?.start !== undefined) {
        const parameter = getQueryStringParameter(performanceMarkQueryStringParam);
        if (
            parameter != undefined &&
            (parameter === '' || parameter.toLowerCase().indexOf(perfMarkName) !== -1)
        ) {
            const perfStart = timings.start + (window.performance.now() - Date.now());
            performance.measure(perfMarkName, { start: perfStart, end: timings.plt });
        }

        addPASKeyMetric(perfMarkName, timings.plt);
    }

    resolvePendingDatapoints();

    postSignal(createBootReliabilityAriaEvent('ok', undefined, timings));
}
