import { logStartUsage } from 'owa-analytics-start';
import type { CustomDataMap } from 'owa-analytics-types';
import { scrubForPii } from 'owa-config/lib/scrubForPii';
import sleep from 'owa-sleep';
import { isNetworkBlocked, isOfflineBootEnabled } from './offlineBoot';
import { isOffline } from './isOffline';

type NetworkRaceResult<T> = {
    value: T;
    customData: CustomDataMap;
};
export type RaceParticipant = any;

/**
 * Helper function to create races between online and offline requests.
 *
 * Instead of passing promises you pass thunks that return promises to avoid
 * executing requests that are not needed.
 *
 * Return networkCall when offline boot is disabled.
 * Return offlineFallback when offline.
 * Races between networkCall and offlineFallback when online.
 *
 * @param label - label for analytics
 * @param networkCallThunk - thunk that returns a promise for the network call
 * @param offlineFallbackThunk - thunk that returns a promise for the offline fallback
 * @param raceCompleteCallback - callback to execute after all promises have resolved
 * @param networkTimeout - timeout for network call in ms before falling back to offline
 */
export function networkRace<T>(
    label: string,
    networkCallThunk: () => Promise<T>,
    offlineFallbackThunk: () => Promise<T>,
    raceCompleteCallback?: (networkValue: T, offlineValue: T, winner: RaceParticipant) => void,
    networkTimeout: number = 500
): Promise<NetworkRaceResult<T>> {
    const customData: CustomDataMap = { label };
    const start = Date.now();
    const endTiming = () => (customData['duration'] = Date.now() - start);
    const endDp = (winner: string) => (value: T) => {
        endTiming();
        customData['winner'] = winner;
        logStartUsage('NetworkRace', customData);
        return {
            value,
            customData,
        };
    };

    if (!isOfflineBootEnabled()) {
        customData['participants'] = 'network';
        return networkCallThunk().then(endDp('network'));
    }

    if (isOffline() || isNetworkBlocked()) {
        customData['participants'] = 'offline';
        return offlineFallbackThunk().then(endDp('offline'));
    }

    customData['participants'] = 'both';

    const racePromises = [networkCallThunk(), sleep(networkTimeout, offlineFallbackThunk())];

    return any(racePromises, label, customData).then(([winner, value]) => {
        if (raceCompleteCallback) {
            Promise.all(racePromises)
                .then(([networkResult, offlineResult]) =>
                    raceCompleteCallback(networkResult, offlineResult, winner)
                )
                .catch(() => {});
        }
        return {
            value,
            customData,
        };
    });
}

/**
 * Similar to Promise.any but with analytics
 */
function any<T>(
    promises: Promise<T>[],
    label: string,
    customData: CustomDataMap
): Promise<[number, T]> {
    const start = Date.now();

    return new Promise((resolve, reject) => {
        const errors: any[] = [];
        const rejectAll = (error: any, p: number) => {
            const scrubbed = scrubForPii(error?.message);
            if (p === 1) {
                customData['networkError'] = scrubbed;
            } else {
                customData['offlineError'] = scrubbed;
            }
            errors.push(error);
            if (errors.length === promises.length) {
                logStartUsage('NetworkRace', customData);
                reject(errors[0]);
            }
        };

        let isResolved = false;
        const resolveOnce = (p: number) => (value: T) => {
            if (!isResolved) {
                isResolved = true;
                customData['winner'] = p;
                customData['duration'] = Date.now() - start;
                logStartUsage('NetworkRace', customData);
                resolve([p, value]);
            } else {
                logStartUsage('RaceParticipant', {
                    l: label,
                    t: Date.now() - start,
                    p,
                });
            }
        };

        let participantCount = 1;
        for (const promise of promises) {
            const participant = participantCount++;
            promise.then(resolveOnce(participant), (error: any) => rejectAll(error, participant));
        }
    });
}
