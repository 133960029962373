import { type HttpStatusCode } from 'owa-http-status-codes';
import isRetriableAuthError from './isRetriableAuthError';

export default function isRetriableStatus(status: number): boolean {
    return (
        status == 0 ||
        status == 408 ||
        status == 449 ||
        status == 500 ||
        status == 501 ||
        status == 502 ||
        status == 503 ||
        status == 504 ||
        isRetriableAuthError(status)
    );
}
