import { getApp } from 'owa-config';
import { redirectToOwa } from './redirectToOwa';
import { redirect, redirectTo, AUTH_REDIRECT_KEY } from './redirect';
import type { BootError, BootResult } from 'owa-shared-start-types';
import type ErrorDiagnostics from './interfaces/ErrorDiagnostics';
import handleErrorLocally from './handleErrorLocally';

export function handleBootError(
    bootResult: BootResult,
    error: BootError,
    errorDiagnostics: ErrorDiagnostics,
    onFatalBootError?: () => void
): void {
    // Check if we need retry before render error page
    let didRedirect: boolean = false;
    if (bootResult == 'langtz') {
        didRedirect = redirectToOwa(getApp());
    } else if (bootResult == 'errorfe' && error.response?.url) {
        didRedirect = redirectTo(error.response.url);
    } else if (bootResult == 'auth') {
        didRedirect = redirect(
            location,
            AUTH_REDIRECT_KEY,
            'true',
            undefined,
            true,
            error?.response?.headers
        );
    }

    if (!didRedirect) {
        // Await this because once we redirect further async stuff won't happen
        onFatalBootError?.();
        handleErrorLocally(bootResult, errorDiagnostics, error);
    }
}
