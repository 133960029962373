import getAccountSourceListStore from '../store/accountSourceListStore';
import type { AccountAndCoprincipalSourceId } from '../store/schema/AccountSourceList';

/**
 * Returns the AccountSource and coprincipal sourceId associated with the specified sourceId
 * @param sourceId The sourceId to be located
 * @returns Information about the account and coprincipal sourceId or undefined if the sourceId is
 *          not found in the store (expected when the source has been removed)
 */
export default function getAccountAndCoprincipalSourceId(
    sourceId: string
): AccountAndCoprincipalSourceId | undefined {
    return getAccountSourceListStore().sourcesBySourceId.get(sourceId);
}
