import type { IExtendedTelemetryItem } from '@microsoft/1ds-core-js';
import { logOriUserMetric } from 'owa-oribuild';
import type { OpxSessionInfo } from 'owa-config';
import { getOpxHostData, scrubForPii } from 'owa-config';
import { getBootType } from 'owa-config/lib/envDiagnostics';
import {
    getCalculateBootTypeError,
    getCdnProvider,
    getSwVersion,
    getCacheResult,
    getCacheAge,
} from 'owa-config/lib/calculateBootType';
import {
    getBootMemory,
    getBootNextHopProtocol,
    getBootTimings,
    getBottlenecks,
    getBottleneckTimings,
    getLayers,
} from 'owa-performance';
import { setPlt } from 'owa-plt-data';
import type { BootError, BootResult } from 'owa-shared-start-types';
import { createBaseEvent } from './createBaseEvent';
import type ErrorDiagnostics from './interfaces/ErrorDiagnostics';
import { appendMiscData, getMiscData } from './miscData';

const resultToEventNameMapping: {
    [P in BootResult]?: string;
} = {
    ok: 'clientstartupsuccess',
    fail: 'clientstartuperror',
    transient: 'clientstartuperror',
};

export async function createBootReliabilityAriaEvent(
    bootResult: BootResult,
    errorDiagnostics?: ErrorDiagnostics,
    timings?: {
        start: number | undefined;
        plt: number | undefined;
        upt: number | undefined;
    },
    bootError?: BootError,
    appOverride?: string
): Promise<IExtendedTelemetryItem[]> {
    let bootType: string | undefined;
    let opxSessionInfo: OpxSessionInfo | undefined;
    try {
        bootType = await getBootType();
        const swVer = getSwVersion();
        appendMiscData('swVer', swVer || typeof swVer);
        const lazyOpxSessionInfo = getOpxHostData();
        if (lazyOpxSessionInfo) {
            opxSessionInfo = await lazyOpxSessionInfo;
        }
        if (bootType === 'Unknown') {
            const error = getCalculateBootTypeError();
            if (error) {
                appendMiscData('cbte', typeof error === 'object' ? error.message : error);
            }
        }
        appendMiscData('cdnProvider', getCdnProvider());
        appendMiscData('nextHopProtocol', getBootNextHopProtocol());

        const cacheResult = getCacheResult();
        appendMiscData('cacheResult', cacheResult || typeof cacheResult);
        const cacheAge = getCacheAge();
        appendMiscData('cacheAge', cacheAge || typeof cacheAge);
    } catch {
        // ignore the error if we can't get the boot type or opx data
    }

    const events: IExtendedTelemetryItem[] = [
        createBootBaseEvent(
            resultToEventNameMapping[bootResult] || 'clientstartupother',
            bootResult,
            errorDiagnostics,
            bootType,
            opxSessionInfo,
            appOverride
        ),
    ];

    if (window.owaBackfilledErrors) {
        for (let ii = 0; ii < window.owaBackfilledErrors.length; ii++) {
            events.push(
                createBootBaseEvent(
                    'bootevalerror',
                    bootResult,
                    errorDiagnostics,
                    bootType,
                    opxSessionInfo,
                    appOverride
                )
            );
        }
    }

    let diagnostics = '';
    if (bootError?.diagnosticInfo) {
        diagnostics += bootError.diagnosticInfo;
    }
    if (window.owaBackfilledErrors && window.owaBackfilledErrors.length > 0) {
        diagnostics += '|' + window.owaBackfilledErrors.map(formatBackfilledErrors).join('|');
    }
    if (diagnostics) {
        setEventProperty(events[0], 'Diagnostics', diagnostics);
    }
    const navigation = window.performance?.navigation;
    if (navigation) {
        setEventProperty(events[0], 'RedirectCount', navigation.redirectCount);
    }

    setPlt(timings?.plt);
    if (timings?.plt) {
        logOriUserMetric({ metric: 'plt', value: timings.plt });
    }

    if (timings) {
        if (timings.start) {
            setEventProperty(events[0], 'StartTime', Date.now() - timings.start);
        }
        if (timings.plt !== undefined) {
            setEventProperty(events[0], 'LoadTime', timings.plt);
        }
        if (timings.upt !== undefined) {
            setEventProperty(events[0], 'UserPromptTime', timings.upt);
        }
    }

    setEventProperty(events[0], 'MiscData', getMiscData(errorDiagnostics, bootError?.response));
    setEventProperty(events[0], 'Timings', getBootTimings());
    setEventProperty(events[0], 'Memory', getBootMemory());

    return events;
}

function formatBackfilledErrors(a: IArguments) {
    let res = 'null';
    if (a) {
        // The first argument should be the error message
        res = a[0];
        if (typeof a.callee == 'function') {
            res += ':' + a.callee();
        }
    }
    return res;
}

function createBootBaseEvent(
    name: string,
    bootResult: BootResult,
    errorDiagnostics: ErrorDiagnostics | undefined,
    bootType: string | undefined,
    opxSessionInfo: OpxSessionInfo | undefined,
    appOverride?: string
) {
    const event = createBaseEvent(name, errorDiagnostics, appOverride);
    if (event.data) {
        event.data.BootResult = bootResult;
        event.data.BottleneckTiming = getBottleneckTimings();
        event.data.Bottlenecks = getBottlenecks();
        event.data.Layers = getLayers();
    }

    setEventProperty(event, 'BootType', bootType);
    if (opxSessionInfo) {
        setEventProperty(event, 'HostedScenario', opxSessionInfo.hostedScenario);
        setEventProperty(event, 'HostTelemetry', scrubForPii(opxSessionInfo.hostTelemetry));
    }
    return event;
}

function setEventProperty(
    event: IExtendedTelemetryItem,
    key: string,
    value: string | number | boolean | undefined
) {
    if (value != null && value != undefined) {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        event.data![key as string] = value;
    }
}
