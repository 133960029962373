import type { BatchWorkerLoggingFunctionResult, LogFunctionName } from 'owa-analytics-types';
import { trace } from 'owa-trace';
import { getAnalyticsAddon } from 'owa-analytics-addons';

let useQueue = true;

type AddAnalyticsCheckpointWrapper = (checkpoint: string) => void;

type QueueItem<Params extends any[]> = {
    args: Params;
};

const queue: {
    [key in LogFunctionName]: QueueItem<any>[];
} = {
    logDatapoint: [],
    logPerformanceDatapoint: [],
    logAddinsCustomerContent: [],
    logAddinsTelemetryEvent: [],
};

const functionsCache = new Map<LogFunctionName, (...p: any[]) => void>();

export function enableAnalyticsQueue() {
    useQueue = true;
}

export function processQueueAfterInitilization(
    addAnalyticsCheckpointWrapper?: AddAnalyticsCheckpointWrapper
) {
    trace.info(
        ` Analytics Initialized. Processing ${Object.values(queue).reduce(
            (acc, curr) => acc + curr?.length ?? 0,
            0
        )} queued events.`,
        'analytics'
    );
    useQueue = false;
    processQueue(addAnalyticsCheckpointWrapper);
}

export function queueOrRun<QueueArgs extends Array<any>>(
    batchFunctionName: LogFunctionName,
    func: (...p: QueueArgs) => void | Promise<void>,
    ...args: QueueArgs
) {
    if (!functionsCache.has(batchFunctionName)) {
        functionsCache.set(batchFunctionName, func);
    }

    if (useQueue) {
        queue[batchFunctionName].push({
            args,
        });
    } else {
        callFunction(func, args);
    }
}

function processQueue(addAnalyticsCheckpointWrapper?: AddAnalyticsCheckpointWrapper) {
    addAnalyticsCheckpointWrapper?.('LogDatapointQueue_s');
    for (const [batchFunctionName, events] of Object.entries(queue)) {
        if (events.length > 0) {
            const addon = getAnalyticsAddon<BatchWorkerLoggingFunctionResult>(
                'LogEventsToWorkerInBatches'
            );
            const status = addon?.isEnabled && addon?.executeNow(batchFunctionName, events);

            // If the worker is not available, fallback to the main thread
            if (!status || status === 'useFallback') {
                batchEventsFallback(batchFunctionName, events);
            }
        }
    }
    addAnalyticsCheckpointWrapper?.('LogDatapointQueue_e');
}

function batchEventsFallback(batchFunctionName: LogFunctionName, events: QueueItem<any>[]) {
    for (const event of events) {
        const func = functionsCache.get(batchFunctionName);
        if (func) {
            callFunction(func, event.args);
        } else {
            trace.warn(
                `Failed to find the function for ${batchFunctionName}. Skipping the event.`,
                'analytics'
            );
        }
    }
}

function callFunction<QueueArgs extends any[]>(func: (...p: QueueArgs) => void, args: QueueArgs) {
    func.apply(null, args);
}
