import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import type TokenResponseCode from 'owa-service/lib/contract/TokenResponseCode';

export const REDIRECT_URI_SUFFIX = '/owa/auth/dt.aspx';
export const LOGOUT_REDIRECT_URI_SUFFIX = '/owa/logoff.owa';
export const AUTHORITY_COMMON = 'https://login.microsoftonline.com/common/';
export const AUTHORITY_ORGANIZATIONS = 'https://login.microsoftonline.com/organizations/';
export const AUTHORITY_CONSUMERS = 'https://login.microsoftonline.com/consumers/';
export const MSA_DEFAULT_SCOPE = 'service::outlook.office.com::MBI_SSL';
export const DC_QUERY_PARAM = 'ESTS-PUB-WUS2-AZ1-FD000-TEST1';
export const EXCHANGE_CONSUMER_TENANT_ID = '84df9e7f-e9f6-40af-b435-aaaaaaaaaaaa';
export const MICROSOFT_CONSUMER_TENANT_ID = '9188040d-6c67-4c5b-b112-36a304b66dad';

export const OUTLOOK_GATEWAY_URL = 'outlookgatewayb2/.*/graphql';
export const NOTIFICATION_CHANNEL_URL = '.*/notificationchannel';
export const RESOURCE_BLOCK_LIST_CONSUMER: string[] = [
    'https://cortana.ai',
    'https://dataservice.o365filtering.com',
    'https://outlookimageproxy.azurewebsites.net',
];
export const RESOURCE_BLOCK_LIST_ENTERPRISE: string[] = [
    'https://cortana.ai',
    'https://dataservice.o365filtering.com',
    'https://outlookimageproxy.azurewebsites.net',
    'https://outlook-sdf.live.com',
    'https://outlook.live.com',
];

export const TOKEN_RESPONSE_UNSUPPORTED_ACCOUNT_TYPE: TokenResponse = {
    TokenResultCode: 2,
    SubErrorCode: 'unsupported_account_type',
};

export const TOKEN_RESPONSE_MSAL_NOT_ENABLED: TokenResponse = {
    TokenResultCode: 2,
    SubErrorCode: 'msal_not_enabled',
};

export const OUTLOOK_COBRAND_ID = 'ab0455a0-8d03-46b9-b18b-df2f57b9e44c';
export const OWA_RESOURCE_URL_ENTERPRISE = [
    'https://outlook.office.com/',
    'https://outlook-sdf.office.com/',
    'https://outlook.office365.com/',
    'https://outlook-sdf.office365.com/',
];
