import { buildWindowData } from 'owa-worker-shared';
import { getWrappedWorker } from '../util/getWrappedWorker';
import { markFunction, addBottleneck, addBottleneckTiming } from 'owa-performance';
import { getConfig } from 'owa-service/lib/config';
import { getBrowserHeight } from 'owa-config';

export const getOfflineSessionData = markFunction(getSessionData, 'offsd');

export function initializeWorker() {
    markFunction(getWrappedWorker, 'dw')();
}

async function getSessionData(app: string) {
    const worker = getWrappedWorker();
    if (!worker?.client) {
        throw new Error('Worker client is not available');
    }

    // If we are in Monarch the account source list store is initialized and we can get
    // the persistenceId for the global settings account. If we are in OWA the account
    // source list store is not initialized until after the session data has been retrieved
    const config = getConfig();
    const mailboxInfo = config.getGlobalSettingsAccountMailboxInfo?.();
    const databaseId = config.getGlobalSettingsAccountPersistenceId?.();
    if (!databaseId || !mailboxInfo) {
        throw new Error('Can not get a account to resolve database');
    }

    const windowData = buildWindowData(false /* includeLocalStorage */);

    const sessionData = await worker.client.getOfflineSessionData(
        windowData,
        app,
        databaseId,
        JSON.parse(JSON.stringify(mailboxInfo)),
        {
            windowHeight: getBrowserHeight(),
        }
    );

    const { bottlenecks, bottleneckTimings } = sessionData;

    for (const [key, value] of Object.entries(bottlenecks)) {
        addBottleneck(key, value);
    }
    for (const [key, value] of Object.entries(bottleneckTimings)) {
        addBottleneckTiming(key, value);
    }

    return sessionData;
}
