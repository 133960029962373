import { logStartCoreUsage } from 'owa-analytics-start/lib/logStartUsage';
import { getWindowData } from 'owa-window-data';
import type { HeadersWithoutIterator } from './RequestOptions';
import { getConfig } from './config';
import { setItem } from 'owa-local-storage';

let isHipErrorHandled: boolean;
export default function checkAndHandleOwaHipError(headers?: HeadersWithoutIterator): boolean {
    const config = getConfig();
    if (
        headers &&
        headers.get('X-OWA-ExtendedErrorCode') == '5' &&
        config.isUserIdle !== undefined
    ) {
        const hipPath = headers.get('X-OWA-ExtendedErrorData');
        const hipType = headers.get('X-OWA-ExtendedErrorMessage');
        const loginArkoseRequiredKey = 'loginArkoseRequired';
        const isArkoseFlowRequired =
            config.isFeatureEnabled('fwk-HighCostArkoseModal') &&
            !!hipType?.includes('HighCostHipRequired');

        if (!isHipErrorHandled && hipPath) {
            if (isArkoseFlowRequired) {
                setItem(self, loginArkoseRequiredKey, '1');
            }

            isHipErrorHandled = true;
            const dpData = { hipPath, hipType };
            if (isArkoseFlowRequired) {
                logStartCoreUsage('highCostCaptchaTriggered', dpData);
            } else {
                logStartCoreUsage('ReactHipRedirect', dpData);
            }

            if (!isArkoseFlowRequired) {
                getWindowData().location.href = hipPath;
            }
        }
    }

    return isHipErrorHandled;
}
