import type { MailboxInfo, MailboxRank, MailboxType } from 'owa-client-types';
import { CoprincipalMailboxRank } from 'owa-client-types';
import type { ExternalData } from './ExternalData';
import type { BootState } from './BootState';
import type { BootStateData } from './BootStateData';
import type { PolicyStateTypeEnum } from './PolicyState';
import type { RemovedAccount, LicensingMailboxInfo } from 'owa-account-source-list-types';
import { AccountSourceType } from 'owa-account-source-list-types';

export enum LoadState {
    NotStarted,
    NotSupported,
    Loading,
    Loaded,
    Error,
}

export interface Contracts {
    supportsCalendar: boolean;
    supportsContacts: boolean;
    supportsMail: boolean;
    supportsSettings: boolean;
    isMicrosoft365Hosted: boolean;
}

export enum M365AuthProvider {
    AAD,
    MSA,
}

export interface M365Mailbox {
    type: MailboxType;
    name: string;
    displayName: string;
    emailAddress: string;
    logonEmailAddress: string;
    userIdentity: string;
    authProviderType: M365AuthProvider;
    routingHint?: string;
    alternateId?: string;
    accountUniqueId?: string;
    imageUrl?: string;
    profileUrl?: string;
    auxiliaryMailboxGuid?: string;
}

// Provides information about the state of the account
export interface AccountState {
    // The numeric value from the OWAConnectedAccountState in owa-account-store package, this
    // value will only be present for client-web applications that have not been migrated
    // to the Monarch multiple account infrastructure
    connectedAccountState: number;
}

export const providerInfo = {
    OFFICE365: AccountSourceType.Office365,
    OUTLOOKDOTCOM: AccountSourceType.OutlookDotCom,
    GOOGLE: AccountSourceType.Google,
    YAHOO: AccountSourceType.Yahoo,
    ICLOUD: AccountSourceType.ICloud,
    IMAP: AccountSourceType.IMAP,
    POP3: AccountSourceType.POP3,
    PSTFILE: AccountSourceType.PstFile,
    OTHER: AccountSourceType.Other,
};

export enum AccountDataType {
    M365Mailbox,
    PstFile,
}

export enum M365MailboxType {
    Archive,
    User,
    Connected,
    Group,
    Shared,
    PublicFolder,
    Teams,
}

export interface AdditionalAccountInfo {
    coprincipalSourceId: string;
    loadState: LoadState;
    sources: AccountSource[];
}

export interface AccountSource {
    sourceId: string;
    sourceType: AccountSourceType;
    dataType: AccountDataType;
    mailboxRank: MailboxRank;
    displayName: string;
    contracts: Contracts;
    externalData: ExternalData;
    readonly mailboxInfo: MailboxInfo;
}

export interface M365AccountSource extends AccountSource {
    m365mailbox: M365Mailbox;
}

/**
 * All Coprincipal account sources will go through a boot process, the bootState
 * tracks whether or not yet have completed the pre-render steps.
 */
export interface CoprincipalAccountSource extends AccountSource {
    bootState: BootState;
    bootStateData?: BootStateData;
    policyState?: PolicyStateTypeEnum;
    persistenceId: string;
    // True if the mailbox this account source represents is a delegate or shared mailbox, that is
    // a mailbox that is shared with the user and is not the user's owned mailbox.
    isSharedWithMe: boolean;
    aliases: string[];
    licensingMailboxInfo: LicensingMailboxInfo;
}

export interface M365UserMailboxAccountSource extends CoprincipalAccountSource, M365AccountSource {
    anchorMailbox?: string;
    m365mailboxType: M365MailboxType.User;
    sharedSources: AdditionalAccountInfo;
    groupSources: AdditionalAccountInfo;
    archiveSources: AdditionalAccountInfo; //Online Archive Account Source
    publicFolderSources: AdditionalAccountInfo;
    teamsSources: AdditionalAccountInfo;
}

export interface M365ArchiveMailboxAccountSource extends M365AccountSource {
    m365mailboxType: M365MailboxType.Archive;
}

export interface M365SharedMailboxAccountSource extends M365AccountSource {
    m365mailboxType: M365MailboxType.Shared;
    isAutomapped: boolean;
}

export interface M365GroupMailboxAccountSource extends M365AccountSource {
    m365mailboxType: M365MailboxType.Group;
}

export interface M365PublicFolderMailboxAccountSource extends M365AccountSource {
    m365mailboxType: M365MailboxType.PublicFolder;
}

export interface M365TeamsMailboxAccountSource extends M365AccountSource {
    m365mailboxType: M365MailboxType.Teams;
}

export interface M365ConnectedMailboxAccountSource
    extends CoprincipalAccountSource,
        M365AccountSource {
    m365mailboxType: M365MailboxType.Connected;
    accountState: AccountState;
}

// For performance reasons we want to be able to look up information about accounts based on just
// the sourceId. When added account sources will be places in the sourcesBySourceId map and will use
// the AccountAndCoprincipalSourceId to provide both the AccountSource and the Coprincial sourceId (if
// there is a Coprincipal account).
export interface AccountAndCoprincipalSourceId {
    source: AccountSource;
    coprincipalSourceId?: string;
}

// Defines the data for the account source list that can be passed between
// the main thread and the worker thread. This does not include the Map object
// as that would be removed in marshalling to the worker.
export interface AccountSourceListData {
    globalSettingsSourceId: string;
    sources: CoprincipalAccountSource[];
    removedAccounts: RemovedAccount[];
}

export enum IndexerMatchType {
    AccountUserIdentity,
    RemovedUserIdentity,
    AccountAlias,
}

export interface IndexerAndMatchInfo {
    indexer: string;
    accountIndex: number;
    matchType: IndexerMatchType;
}

export interface AccountSourceList extends AccountSourceListData {
    // Index the sources in the account source list by sourceId
    sourcesBySourceId: Map<string, AccountAndCoprincipalSourceId>;
    indexerByUserIdentity: Map<string, IndexerAndMatchInfo>;
}

export const accountSourceDataTypeChecker = {
    isM365Mailbox: (account?: AccountSource): account is M365AccountSource =>
        account?.dataType === AccountDataType.M365Mailbox,
    isM365UserMailbox: (account?: AccountSource): account is M365UserMailboxAccountSource =>
        accountSourceDataTypeChecker.isM365Mailbox(account) &&
        (account as any).m365mailboxType === M365MailboxType.User,
    isM365ArchiveMailbox: (account?: AccountSource): account is M365ArchiveMailboxAccountSource =>
        accountSourceDataTypeChecker.isM365Mailbox(account) &&
        (account as any).m365mailboxType === M365MailboxType.Archive,
    isM365ConnectedMailbox: (
        account?: AccountSource
    ): account is M365ConnectedMailboxAccountSource =>
        accountSourceDataTypeChecker.isM365Mailbox(account) &&
        (account as any).m365mailboxType === M365MailboxType.Connected,
    isM365GroupMailbox: (account?: AccountSource): account is M365GroupMailboxAccountSource =>
        accountSourceDataTypeChecker.isM365Mailbox(account) &&
        (account as any).m365mailboxType === M365MailboxType.Group,
    isM365SharedMailbox: (account?: AccountSource): account is M365SharedMailboxAccountSource =>
        accountSourceDataTypeChecker.isM365Mailbox(account) &&
        (account as any).m365mailboxType === M365MailboxType.Shared,
    isM365PublicFolderMailbox: (
        account?: AccountSource
    ): account is M365PublicFolderMailboxAccountSource =>
        accountSourceDataTypeChecker.isM365Mailbox(account) &&
        (account as any).m365mailboxType === M365MailboxType.PublicFolder,
    isM365TeamsMailbox: (account?: AccountSource): account is M365TeamsMailboxAccountSource =>
        accountSourceDataTypeChecker.isM365Mailbox(account) &&
        (account as any).m365mailboxType === M365MailboxType.Teams,
    isPstFile: (account?: AccountSource): account is CoprincipalAccountSource =>
        account?.dataType === AccountDataType.PstFile,
};

export const accountRankTypeChecker = {
    isCoprincipal: (account?: AccountSource): account is CoprincipalAccountSource =>
        account?.mailboxRank === CoprincipalMailboxRank,
};

export const m365MailboxTypeToMailboxType = (m365mailboxType: M365MailboxType): MailboxType => {
    switch (m365mailboxType) {
        case M365MailboxType.Archive:
            return 'ArchiveMailbox';
        case M365MailboxType.Connected:
        case M365MailboxType.User:
            return 'UserMailbox';
        case M365MailboxType.Group:
            return 'GroupMailbox';
        case M365MailboxType.PublicFolder:
            return 'PublicMailbox';
        case M365MailboxType.Shared:
            return 'SharedMailbox';
        case M365MailboxType.Teams:
            return 'TeamsMailbox';
        default:
            const _neverValue: never = m365mailboxType;
            return _neverValue;
    }
};
