import type MailboxInfo from './MailboxInfo';
import getIndexerValueForMailboxInfo, {
    defaultIndexerValue,
} from './getIndexerValueForMailboxInfo';

export default function isSameCoprincipalAccountMailboxAndIndexer(
    mailbox1Info: MailboxInfo | undefined,
    mailbox2Index: string | undefined
): boolean {
    let isSame = false;

    if (mailbox1Info && mailbox2Index) {
        const mailbox1Index = getIndexerValueForMailboxInfo(mailbox1Info);

        if (
            mailbox1Index !== defaultIndexerValue &&
            mailbox1Index !== '' &&
            mailbox2Index !== defaultIndexerValue &&
            mailbox2Index !== ''
        ) {
            isSame = mailbox1Index === mailbox2Index;
        }
    }

    return isSame;
}
