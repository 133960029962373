import { getHostLocation } from './hostLocation';
import isHostedEndpoint from './isHostedEndpoint';

export default function getRootVdirName(shouldPreserveHostedPath?: boolean): string | null {
    const pathname = getHostLocation()?.pathname;
    if (pathname) {
        const subPaths = pathname.split('/').filter(part => !!part);
        if (isHostedEndpoint()) {
            if (shouldPreserveHostedPath && subPaths[1]) {
                // Preserve hosted endpoint such that the path is hosted/{AppName}
                return [subPaths[0], subPaths[1]].join('/').toLowerCase();
            } else if (subPaths[1]) {
                return subPaths[1].toLowerCase();
            } else {
                return null;
            }
        } else {
            return subPaths[0] ? subPaths[0].toLowerCase() : null;
        }
    }
    return null;
}
