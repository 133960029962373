const WindowHeightBuffer = 250;
const ReadingPaneOffHeighToMessageRatio = 29;
const MinimumMessageCount = 10;

export function getMessageCount(windowHeight: number): number {
    const messageCount = Math.ceil(
        (windowHeight + WindowHeightBuffer) / ReadingPaneOffHeighToMessageRatio
    );
    return messageCount < MinimumMessageCount ? MinimumMessageCount : messageCount;
}
