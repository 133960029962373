import type { StartConfig } from 'owa-shared-start';
import { sharedStart } from 'owa-shared-start';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import { preloadLocStrings, LocalStoreLocaleKey } from 'owa-localize-internal';
import { fetchThemeFile, ThemeConstants } from 'owa-theme-shared';
import { getItem } from 'owa-local-storage';
import { getBposNavBarDataAsync, trySetBposNavBarData } from 'owa-bpos-store';
import { updateServiceConfig } from 'owa-service/lib/config';
import {
    lazyInitializeMetaOsAppSdkProxy,
    lazyGetOwaAuthTokenFromMetaOsHubProxy,
    lazyGetOwaDecodedAuthTokenFromMetaOsHubProxy,
    lazyInitializeMetaOsCapabilitiesProxy,
    lazyGetContextProxy,
    lazyPreInitOwaAccountSourceListStoreProxy,
    lazyRegisterOnLoadHandlerProxy,
    lazyRegisterBeforeUnloadHandlerProxy,
    lazyGetAccountServiceConfigProxy,
} from 'owa-metaos-app-bootstrap/lib/lazyAppBoot';
import { isRunningInMetaOSHub, isHosted, setHostHub } from 'owa-config';
import { createBposResolver } from 'owa-bpos-store/lib/bposNavBarData';
import type WebSessionType from 'owa-service/lib/contract/WebSessionType';
import { isBootFeatureEnabled } from 'owa-metatags';
import { markFunction, addBootCustomData } from 'owa-performance';

const localStorageSource = 'ls';
const functionName = 'start';

export default function start(config: StartConfig): Promise<any> {
    if (isRunningInMetaOSHub()) {
        lazyRegisterOnLoadHandlerProxy.importAndExecute();
        lazyRegisterBeforeUnloadHandlerProxy.importAndExecute();
        const oldRunBeforeStart = config.runBeforeStart;

        config.runBeforeStart = (startConfig: StartConfig) =>
            markFunction(
                lazyInitializeMetaOsAppSdkProxy.import,
                'MetaOSAppBootImportScript'
            )().then(initializeMetaOsAppSdk =>
                initializeMetaOsAppSdk().then(() =>
                    lazyGetOwaAuthTokenFromMetaOsHubProxy
                        .import()
                        .then(getAuthToken => {
                            updateServiceConfig({ getAuthToken });
                            lazyInitializeMetaOsCapabilitiesProxy.importAndExecute();
                            return lazyGetContextProxy.importAndExecute();
                        })
                        .then(async metaOSContext => {
                            const metaOsAppInfo = metaOSContext?.app;
                            const metaOsHostInfo = metaOsAppInfo?.host;
                            const metaOsHostName = metaOsHostInfo?.name;
                            const metaOsHostClientType = metaOsHostInfo?.clientType;

                            setHostHub(metaOsHostName, metaOsHostClientType);
                            updateServiceConfig({
                                metaOSContext: {
                                    locale: metaOsAppInfo?.locale || 'en-us',
                                    hostName: metaOsHostName,
                                    clientType: metaOsHostClientType,
                                },
                            });
                            const userClickTime = metaOsAppInfo?.userClickTime;
                            if (userClickTime) {
                                addBootCustomData('HubLoadData', { userClickTime });
                            }

                            // Initialize the account source list store for Calendar metaos app using PUID
                            if (config.app === 'Calendar') {
                                const decodedToken =
                                    await lazyGetOwaDecodedAuthTokenFromMetaOsHubProxy.importAndExecute();
                                // Cleanup once MetaOS begins to transmit PUID https://outlookweb.visualstudio.com/Outlook%20Web/_workitems/edit/253607
                                if (decodedToken?.puid) {
                                    await markFunction(
                                        lazyPreInitOwaAccountSourceListStoreProxy.importAndExecute,
                                        'MetaOSInitAccountStore'
                                    )(
                                        decodedToken.puid,
                                        metaOSContext?.user?.tenant?.id,
                                        metaOSContext?.user?.loginHint,
                                        metaOSContext?.user?.userPrincipalName
                                    );
                                    updateServiceConfig(
                                        await lazyGetAccountServiceConfigProxy.importAndExecute()
                                    );
                                }
                            }
                            return oldRunBeforeStart?.(startConfig);
                        })
                )
            );
    }

    return sharedStart({
        ...config,
        runAfterRequests: (sessionDataPromise: Promise<SessionData>) => {
            // try to load the theme data from local storage
            const cachedNormalizedTheme = getItem(window, ThemeConstants.LOCAL_STORAGE_KEY);
            if (cachedNormalizedTheme) {
                fetchThemeFile(cachedNormalizedTheme, localStorageSource);
            }

            const cachedLocale = getItem(window, LocalStoreLocaleKey);
            if (cachedLocale) {
                document.documentElement.lang = cachedLocale;
            }

            preloadLocStrings(localStorageSource);

            // load the bpos data as soon as possible so we can start rendering the header as soon as possible
            if (config.loadBpos) {
                const resolveBpos = createBposResolver();
                sessionDataPromise.then(sd => {
                    // we only call getBposNavBarDataAsync if the NavBarData is not already set
                    // Bookings has a custom NavBarData that we don't want to overwrite
                    const hasBposData = sd?.owaUserConfig?.NavBarData && config.app !== 'Bookings';

                    // consumer users don't need NavBarData and we can set it to null
                    const isConsumer = sd?.owaUserConfig?.SessionSettings?.WebSessionType !== 0;

                    if (isHosted() && isBootFeatureEnabled('fwk-skipnavbardataonhosted')) {
                        // Hosted scenarios should not need NavBarData
                        resolveBpos(() => trySetBposNavBarData(null));
                    } else if (hasBposData || isConsumer) {
                        resolveBpos(() =>
                            trySetBposNavBarData(sd?.owaUserConfig?.NavBarData ?? null)
                        );
                    } else {
                        resolveBpos(() => getBposNavBarDataAsync(functionName, config.app));
                    }
                });
            }

            // Initialize localization once we have the user culture
            sessionDataPromise
                .then(() => {
                    preloadLocStrings('sd');
                })
                .catch(() => {});

            config.runAfterRequests?.(sessionDataPromise);
        },
    });
}
