import { getConfig } from './config';
import { type HttpStatusCode } from 'owa-http-status-codes';
import { tryBackgroundAuth } from './tryBackgroundAuth';
import type { TraceErrorObject } from 'owa-trace';
import type { RequestOptions } from './RequestOptions';
import type MailboxRequestOptions from './MailboxRequestOptions';
import { isAuthNeeded } from './isAuthNeeded';
import { doAuthRedirect } from './doAuthRedirect';
import checkAndHandleOwaHipError from './checkAndHandleOwaHipError';

const AuthError = 'NeedsAuth';

export default function fetchHandler<T>(
    actionName: string,
    response: ResponseWithExtraParams,
    requestOptions: RequestOptions | MailboxRequestOptions,
    callstackAtRequest?: string
): Promise<T | Response> {
    const errorMessage = getErrorMessage(response, requestOptions);
    if (requestOptions.returnFullResponseOnSuccess) {
        response.callstackAtRequest = callstackAtRequest;
        response.responseErrorMessage = `RequestFailed:${actionName} with ${response.source}`;
        return errorMessage ? Promise.reject<Response>(response) : Promise.resolve(response);
    }

    if (errorMessage) {
        const responseError = createServerFailureError(
            actionName,
            errorMessage,
            response,
            callstackAtRequest
        );
        responseError.response = response;
        throw responseError;
    }

    if (response.headers?.get('X-OWA-STO') != null) {
        tryBackgroundAuth(requestOptions);
    }

    if (requestOptions.returnResponseHeaders) {
        return Promise.resolve(response);
    }
    return response.json().catch((e: Error) => {
        throw createServerFailureError(actionName, e?.message, response, callstackAtRequest);
    });
}

interface ResponseWithExtraParams extends Response {
    source?: string;
    callstackAtRequest?: string;
    responseErrorMessage?: string;
}

/**
 * Handles response errors. Returns null if no errors
 */
function getErrorMessage(
    response: ResponseWithExtraParams,
    requestOptions: RequestOptions | MailboxRequestOptions
): string | null {
    const config = getConfig();
    if (response.status == 401 || response.status == 440) {
        if (isAuthNeeded(requestOptions)) {
            // we only need to redirect if we are not doing token based auth
            if (config.onAuthFailed) {
                config.onAuthFailed(response.headers);
            } else {
                doAuthRedirect(response.headers, requestOptions.mailboxInfo);
            }
            response.source = AuthError;
            return AuthError;
        }
    } else if (!response.ok) {
        // Check if client is getting OwaHipRequiredExcpetion then he should be redirected to jsMvvm hip control
        // After user successfuly resolve captcha, he will be redirected to /owa which will force opt in user to /mail
        if (response.status == 412 && checkAndHandleOwaHipError(response.headers)) {
            response.source = AuthError;
            return AuthError;
        } else {
            return (
                (response.headers && response.headers.get('x-owa-error')) ||
                response.statusText ||
                (response.status === 449 ? 'RetryWith' : '')
            );
        }
    }

    return null;
}

function createServerFailureError(
    actionName: string,
    message: string,
    response: Response,
    callstackAtRequest: string | undefined
) {
    /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
     * Error constructor names can only be a string literals.
     *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
    const responseError: TraceErrorObject = new Error(`${actionName} failed: ${message}`);
    if (callstackAtRequest) {
        responseError.diagnosticInfo = callstackAtRequest;
    }
    responseError.fetchErrorType = message == AuthError ? 'AuthNeeded' : 'ServerFailure';
    responseError.httpStatus = response.status;
    responseError.xowaerror = response.headers.get('x-owa-error') || undefined;
    responseError.xinnerexception = response.headers.get('x-innerexception') || undefined;
    return responseError;
}
