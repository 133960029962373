import { getConfig } from './config';

let callbacks: (() => void)[] = [];
let unblocked = false;
export function runAfterInitialRender(
    callback: () => Promise<void>,
    shouldGovern: boolean,
    addToFront: boolean | undefined
) {
    const { govern } = getConfig();
    const cb = () => (govern && shouldGovern ? govern(callback) : callback());
    if (unblocked) {
        cb();
    } else if (addToFront) {
        callbacks.unshift(cb);
    } else {
        callbacks.push(cb);
    }
}

export function unblockLazyLoadCallbacks() {
    unblocked = true;
    for (var ii = 0; ii < callbacks.length; ii++) {
        callbacks[ii]();
    }
    callbacks = [];
}

export function blockLazyLoadCallbacks() {
    unblocked = false;
}
