import { isMsalEnabledForBusiness } from './isMsalEnabledForBusiness';
import { isMsalEnabledForConsumerInZeroAccount } from './isMsalEnabledForConsumerInZeroAccount';
import { isMonitoringProbe } from './isMonitoringProbe';

// Checks whether the Msal flow is enabled for the current user.
export function isMsalFlowEnabled(): boolean {
    return (
        isMsalEnabledForConsumerInZeroAccount() || isMsalEnabledForBusiness() || isMonitoringProbe()
    );
}
