import type LazyModuleConfig from '../types/LazyModuleConfig';

const loadedImports: Record<string, boolean> = {};

let config: LazyModuleConfig = {
    markImportAsLoaded: (id: string) => {
        loadedImports[id] = true;
    },
    isImportLoaded: (id: string) => loadedImports[id],
    logUsage: () => {},
    logError: () => {},
    trace: () => {},
};

export function getConfig() {
    return config;
}

export function setConfig(newConfig: LazyModuleConfig) {
    // Copy over any imports that have already loaded
    for (const id of Object.keys(loadedImports)) {
        newConfig.markImportAsLoaded(id);
    }

    // Replace the old config
    config = newConfig;
}

export function stopAutoGovernForBundling() {
    config.govern = undefined;
}
