const timingMap: Map<number, string> = new Map();

export function getTimingMap() {
    return timingMap;
}

export function clearTimingMap() {
    timingMap.clear();
}

type TimingPrefix =
    | 'keytrap'
    | 'reactionScheduler'
    | 'resetFocus'
    | 'useCustomAnimationFrame'
    | 'useCustomTimeout'
    | 'useResizeObserver';

export function addToTimingMap(prefix: TimingPrefix, value: string) {
    if (typeof performance?.now === 'function') {
        timingMap.set(performance.now(), `${prefix}:${value}`);
    }
}
