/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Deprecating getPhysicalRing.ts
 *	> 'isDogfoodEnv' import from 'owa-metatags' is restricted. This value is resolved in ECS as a filter. Please create a feature flight if possible. */
import { isDogfoodEnv } from 'owa-metatags';
import { hasQueryStringParameter } from 'owa-querystring';
import { addBootMemoryReading } from './trackMemory';

export const shouldTrackBootQueryStringParam = 'bpm';

// This is max allowed value for 1 symbol in UTF8, which is 1 string character in Kusto;
const MAXVAL = 1114111;

const isPerformanceSupported = self?.performance;
const bootTimings: {
    [index: string]: number | string;
} = {};

export function addBootTiming(name: string, timing?: number) {
    if (hasQueryStringParameter(shouldTrackBootQueryStringParam)) {
        self.performance.mark(name, timing ? { startTime: timing } : undefined);
    }

    if (isPerformanceSupported) {
        bootTimings[name] = timing || Math.floor(performance.now());
        addBootMemoryReading(name);
    }
}

export function getBootTimings() {
    const timing = isPerformanceSupported && self.performance.timing;
    if (timing) {
        bootTimings['in_e'] = timing.responseEnd - timing.fetchStart;

        if (isDogfoodEnv()) {
            const startingPoint = timing.navigationStart;

            /** Metrics is a UTF8 string of 21n symbols,
             each symbol represents diff from starting point
             Starting point is navigationStart
             Here is an order of events
             0: redirectStart || 0
             1: redirectEnd || 0
             2: unloadEventStart || 0
             3: unloadEventEnd || 0
             4: fetchStart
             5: domainLookupStart
             6: domainLookupEnd
             7: connectStart
             8: secureConnectionStart || 0
             9: connectEnd
             10: requestStart
             11: responseStart
             12: responseEnd
             13: domLoading
             14: domInteractive
             15: domComplete
             16: domContentLoadedEventStart
             17: domContentLoadedEventEnd
             18: loadEventStart
             19: loadEventEnd
             20: workerStart ( != 0 if browser has support for new timings API (Not Safari, Not IE))
             **/

            const timingFromNewApi =
                isPerformanceSupported &&
                (self.performance?.getEntriesByType(
                    'navigation'
                )?.[0] as PerformanceNavigationTiming);
            const workerStart = timingFromNewApi?.workerStart || 0;

            const metrics = String.fromCharCode(
                ...[
                    timing.redirectStart - startingPoint,
                    timing.redirectEnd - startingPoint,
                    timing.unloadEventStart - startingPoint,
                    timing.unloadEventEnd - startingPoint,
                    timing.fetchStart - startingPoint,
                    timing.domainLookupStart - startingPoint,
                    timing.domainLookupEnd - startingPoint,
                    timing.connectStart - startingPoint,
                    timing.secureConnectionStart - startingPoint,
                    timing.connectEnd - startingPoint,
                    timing.requestStart - startingPoint,
                    timing.responseStart - startingPoint,
                    timing.responseEnd - startingPoint,
                    timing.domLoading - startingPoint,
                    timing.domInteractive - startingPoint,
                    timing.domComplete - startingPoint,
                    timing.domContentLoadedEventStart - startingPoint,
                    timing.domContentLoadedEventEnd - startingPoint,
                    timing.loadEventStart - startingPoint,
                    timing.loadEventEnd - startingPoint,
                    workerStart,
                ].map(t => Math.min(Math.max(t, 0), MAXVAL))
            );
            bootTimings['fl'] = metrics;
        }
    }

    try {
        if (typeof self.performance?.getEntriesByType == 'function') {
            const resourceTimings = (
                self.performance.getEntriesByType('resource') as PerformanceResourceTiming[]
            )?.find(rt => rt.name.includes('mailindex'));
            if (resourceTimings) {
                bootTimings.mi_s = resourceTimings.startTime;
                bootTimings.mi_e = resourceTimings.responseEnd;
            }
        }
    } catch {
        /* no-op */
    }
    return JSON.stringify(bootTimings);
}

export function getLayers() {
    const layers = Object.keys(bootTimings)
        .filter(b => b.indexOf('_e') > -1)
        .reduce((agg, key) => {
            const label = key.split('_')[0];
            const start = bootTimings[label + '_s'];
            const end = bootTimings[label + '_e'];
            if (typeof start == 'number' && typeof end == 'number') {
                agg[label] = end - start;
            }
            return agg;
        }, {} as Record<string, number>);
    return JSON.stringify(layers);
}

let nextHopProtocol = 'none';
export function getBootNextHopProtocol() {
    if (isPerformanceSupported) {
        const performanceResourceTiming = self.performance?.getEntriesByType(
            'resource'
        )?.[0] as PerformanceResourceTiming;
        const nextHopProtocolValue = performanceResourceTiming?.nextHopProtocol;

        if (nextHopProtocolValue != null) {
            nextHopProtocol = nextHopProtocolValue;
        }
    }

    return nextHopProtocol;
}
