// HostApp values
export const OUTLOOK_DESKTOP = 'outlookdesktop';
export const MAC_OUTLOOK = 'macoutlook';
export const IOS_OUTLOOK = 'iosoutlook';
export const ANDROID_OUTLOOK = 'androidoutlook';
export const ANDROID_OUTLOOK_LITE = 'outlooklite';
export const OUTLOOK_EXTENSION = 'outlookextension';
export const NATIVE = 'native';
export const O365_SHELL = 'o365shell';
export const TEAMS = 'teams';
export const ANDROID_TEAMS = 'androidteams';
export const IOS_TEAMS = 'iosteams';
export const WIDGET = 'widget';
export const WIN32_OUTLOOK_HUB = 'win32outlookhub';
export const TEAMS_HUB = 'teamshub';
export const HUB = 'hub';
export const OWA_HUB = 'owahub';
export const MONARCH_HUB = 'monarchhub';
export const OFFICE_WEB = 'officeweb';
export const OFFICE_DESKTOP = 'officedesktop';
export const ANDORID_OUTLOOK_HUB = 'androidhub';
export const IOS_OUTLOOK_HUB = 'iosoutlookhub';
export const MAC_OUTLOOK_HUB = 'macoutlookhub';
export const SHAREPOINT = 'sharepoint';
export const LOOP_APP = 'loopapp';
