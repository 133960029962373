import { getRetryStrategyFromError } from './getRetryStrategyFromError';
import { createBootReliabilityAriaEvent } from './createBootReliabilityAriaEvent';
import { postSignal } from './ariaUtils';
import type { BootError } from 'owa-shared-start-types';
import type StartConfig from './interfaces/StartConfig';
import type RetryStrategy from './interfaces/RetryStrategy';
import { TEN_SECONDS_IN_MS } from './timeConstants';
import calculateBootResult from './calculateBootResult';
import { getServerErrorDiagnostics } from './getServerErrorDiagnostics';
import { handleBootError } from './handleBootError';
import { doErrorRetryRedirect } from './doErrorRetryRedirect';

export async function onBootError(error: BootError, config: StartConfig): Promise<any> {
    const errorDiagnostics = getServerErrorDiagnostics(error);
    let bootResult = calculateBootResult(error, errorDiagnostics);
    const retryStrategy: RetryStrategy | null =
        config.overrideRetryStrategy?.(bootResult) ||
        getRetryStrategyFromError(bootResult, errorDiagnostics.esrc, true /* retryWholeApp */);
    if (retryStrategy) {
        bootResult = 'retry';
    }

    if (!config.skipStartupSignal) {
        // if we are not retrying, let's do the default time out. If not, let's extend the time out to 10 seconds
        await postSignal(
            createBootReliabilityAriaEvent(bootResult, errorDiagnostics, undefined, error),
            retryStrategy ? undefined : TEN_SECONDS_IN_MS
        );
    }

    const didRetry = retryStrategy && doErrorRetryRedirect(retryStrategy, config);
    if (!didRetry) {
        (config.handleBootError || handleBootError)(
            bootResult,
            error,
            errorDiagnostics,
            config.onFatalBootError
        );
    }
}
