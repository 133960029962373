import { mutatorAction } from 'satcheljs';
import getAccountBySourceId from '../selectors/getAccountBySourceId';
import { accountRankTypeChecker } from '../store/schema/AccountSourceList';
import { AccountSourceType, type LicensingMailboxInfo } from 'owa-account-source-list-types';

export default mutatorAction('addEasidAsLicensingMailboxInfo', function (sourceId: string): void {
    const accountSource = getAccountBySourceId(sourceId);

    if (accountRankTypeChecker.isCoprincipal(accountSource)) {
        const { mailboxSmtpAddress } = accountSource.mailboxInfo;

        const easidMailboxInfo: LicensingMailboxInfo = {
            type: 'UserMailbox',
            userIdentity: mailboxSmtpAddress,
            mailboxSmtpAddress,
            sourceType: AccountSourceType.OutlookDotCom,
            mailboxRank: 'Licensing',
            diagnosticData: 'EasidAsLicensingMailboxInfo',
        };

        accountSource.licensingMailboxInfo = easidMailboxInfo;
    }
});
