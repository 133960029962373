import { getHostLocation } from './hostLocation';

export default function isHostedEndpoint(): boolean {
    const pathname = getHostLocation()?.pathname;
    if (pathname) {
        const subPaths = pathname.split('/').filter(part => !!part);
        return !!subPaths[0] && subPaths[0].toLowerCase() === 'hosted';
    }
    return false;
}
