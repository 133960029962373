import { intercept } from 'mobx';
import assert from 'owa-assert/lib/assert';
import { CoprincipalMailboxRank, getIndexerValueForMailboxInfo } from 'owa-client-types';
import { type TraceErrorObject, errorThatWillCauseAlert, trace } from 'owa-trace';
import addCoprincipalAccountMutator from '../actions/addCoprincipalAccount';
import onCoprincipalAccountAdded from '../actions/onCoprincipalAccountAdded';
import { default as getAccountBySourceId } from '../selectors/getAccountBySourceId';
import { updateCoprincipalUserIdentities } from './coprincipalUserIdentities';
import getCoprincipalAccountForIndexerValue from './getCoprincipalAccountForIndexerValue';
import { updateMailboxInfo } from './updateMailboxInfo';
import type { IValueWillChange } from 'mobx';
import type { CoprincipalAccountSource } from '../store/schema/AccountSourceList';
import { logGreyErrorFromAccounts } from 'owa-account-analytics';

/**
 * Checks to make sure that the indexer value of the coprincipal account is unique. If the indexer
 * value is not unique this method will throw
 * @param accountSource Coprincipal account that is to be added
 */
function checkThatIndexerIsUnique(accountSource: CoprincipalAccountSource) {
    const indexer = getIndexerValueForMailboxInfo(accountSource.mailboxInfo);
    const existingAccount = getCoprincipalAccountForIndexerValue(indexer);
    if (!!existingAccount) {
        // There is already a coprincipal account with the same indexer in the store. This account
        // should not have be added to the store and we should alert the system that there is an
        // error in the build.
        const error: TraceErrorObject = new Error('DuplicateCoprincipalIndexer');
        const diagnosticData = {
            type: accountSource.sourceType,
            existingType: existingAccount.sourceType,
            areSameType: accountSource.sourceType === existingAccount.sourceType,
            areSameSmtp:
                accountSource.mailboxInfo.mailboxSmtpAddress ===
                existingAccount.mailboxInfo.mailboxSmtpAddress,
            areSameUid:
                accountSource.mailboxInfo.userIdentity === existingAccount.mailboxInfo.userIdentity,
        };

        error.diagnosticInfo = JSON.stringify(diagnosticData);
        errorThatWillCauseAlert('DuplicateCoprincipalIndexer', error);
    }
}

/**
 * Interceptor handler for changes to the MailboxInfo, this will report that a
 * change happened and stop the change.
 */
function alertOnMailboxInfoModification<T>(
    _change: IValueWillChange<T>
): IValueWillChange<T> | null {
    // TODO: once the cal-event-merge-immutable-mailboxInfo is rolled out make this an alert again.
    // This is tracked by "Task 279110: Make AccountSourceMailboxInfoChanged an alert again"
    // errorThatWillCauseAlert('AccountSourceMailboxInfoChanged');

    const error = new Error('AccountSourceMailboxInfoChanged');
    logGreyErrorFromAccounts('AccountSourceMailboxInfoChanged', error);

    // The MailboxInfo should be immutable, so we should not allow changes to it.
    return null;
}

/**
 * The MailboxInfo of the account source is expected to be immutable, there have been issues
 * with the information in the MailboxInfo of the coprincipal account has been modified. To
 * make detecting these changes eaiser we add intercepts for the MailboxInfo and it's members.
 * If a change is made these will cause an alert to be raised.
 *
 * @param sourceId The sourceId of the coprincipal account to be made immutable
 */
function interceptAndAlertOnMailboxInfoChanges(sourceId: string) {
    const account = getAccountBySourceId(sourceId);
    if (account) {
        intercept(account, 'mailboxInfo', alertOnMailboxInfoModification);
        intercept(account.mailboxInfo, 'type', alertOnMailboxInfoModification);
        intercept(account.mailboxInfo, 'userIdentity', alertOnMailboxInfoModification);
        intercept(account.mailboxInfo, 'mailboxSmtpAddress', alertOnMailboxInfoModification);
        intercept(account.mailboxInfo, 'auxiliaryMailboxGuid', alertOnMailboxInfoModification);
        intercept(account.mailboxInfo, 'sourceId', alertOnMailboxInfoModification);
        intercept(account.mailboxInfo, 'mailboxRank', alertOnMailboxInfoModification);
    }
}

/**
 * Preforms validation on the account source and then adds the account
 * @param accountSource
 */
export default function addCoprincipalAccount(accountSource: CoprincipalAccountSource): void {
    assert(!getAccountBySourceId(accountSource.sourceId), 'Found duplicated account in the store.');

    assert(accountSource.mailboxRank === CoprincipalMailboxRank, 'Must be a Coprincipal account');

    trace.info(
        `[source-list-store] Adding coprincipal account with boot state ${accountSource.bootState}`
    );

    accountSource = updateMailboxInfo(accountSource) as CoprincipalAccountSource;
    checkThatIndexerIsUnique(accountSource);
    addCoprincipalAccountMutator(accountSource);

    interceptAndAlertOnMailboxInfoChanges(accountSource.sourceId);
    updateCoprincipalUserIdentities();
    onCoprincipalAccountAdded(accountSource);
}
