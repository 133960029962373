import { logUsageFromAccounts } from 'owa-account-analytics';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Deprecating getPhysicalRing.ts
 *	> 'isDogfoodEnv' import from 'owa-metatags' is restricted. This value is resolved in ECS as a filter. Please create a feature flight if possible. */
import { isDogfoodEnv, isBootFeatureEnabled } from 'owa-metatags';

// We only want to report the top 10 default / reflow errors
const MaxErrorsToLog = 10;
let defaultErrorCount = 0;
let reflowErrorCount = 0;

const errorsByIsReflow = new Map<boolean, Set<string>>([
    [false, new Set<string>()],
    [true, new Set<string>()],
]);

// Allows resetting the variables used in logDefaultOrReflowCall, this allows the unit tests
// to validate the logic and the production code to capture data outside of startup
export function resetDefaultOrReflowCalls() {
    defaultErrorCount = 0;
    reflowErrorCount = 0;
    errorsByIsReflow.get(false)?.clear();
    errorsByIsReflow.get(true)?.clear();
}

// Current log collection data is biased toward startup, we want to collection the data again
// after 5 minutes and 10 minutes of the application running.
const MaxCallTelemetryResets = 2;
const ResetDelay = 300000;
let resetCount = 0;
let isResetPending = false;

// Starts a timer for the next reset if needed
function ensureResetTimerIfNeeded() {
    if (resetCount < MaxCallTelemetryResets && !isResetPending) {
        ++resetCount;
        isResetPending = true;
        setTimeout(() => {
            resetDefaultOrReflowCalls();
            isResetPending = false;
        }, ResetDelay);
    }
}

// Unit test helpers to clear the variables used to track resetting the default / reflow calls
export function clearResetTimerForUnitTests() {
    resetCount = 0;
    isResetPending = false;
}

function isReflowCall(defaultOrReflowFunction: string) {
    switch (defaultOrReflowFunction) {
        case 'getAccountSourceListStore':
        case 'getSelectedAccount':
        case 'setSelectedAccount':
            return true;

        default:
            return false;
    }
}

/**
 * Logs calls to reflow functions that are used in reflow.
 * @param reflowFunction Name of the reflow function that is being called
 */
export default function logDefaultOrReflowCall(
    defaultOrReflowFunction:
        | 'getUserMailboxInfo'
        | 'getDefaultLogonEmailAddress'
        | 'getDefaultUserEmailAddress'
        | 'isDefaultMailbox'
        | 'getAccountSourceListStore'
        | 'getSelectedAccount'
) {
    // Because this will create a high volume of telemetry we only want to make the call
    // when we are in dogfood and the boot flight is enabled.
    if (
        isDogfoodEnv() &&
        isBootFeatureEnabled('acct-reflowAndDefaultTelemetry') &&
        (defaultErrorCount < MaxErrorsToLog || reflowErrorCount < MaxErrorsToLog)
    ) {
        // Make sure we can collect the additional telemetry if needed
        ensureResetTimerIfNeeded();

        // we want to log the top 10 errors for both reflow / default categories. We log the two
        // different buckets so we can work on fixing the buckets individually
        const isReflow = isReflowCall(defaultOrReflowFunction);
        if ((isReflow ? reflowErrorCount : defaultErrorCount) >= MaxErrorsToLog) {
            // do not need to log any more errors for reflow/default so there is no
            // need to check if the error is unique
            return;
        }

        const errorMessage = 'AcctDefaultOrReflow-' + defaultOrReflowFunction;
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        const stack = new Error(errorMessage).stack;
        if (stack) {
            const errorSet = errorsByIsReflow.get(isReflow);
            if (errorSet && !errorSet.has(stack)) {
                /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
                 * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
                 *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
                logUsageFromAccounts(errorMessage, {
                    s: stack,
                });

                errorSet.add(stack);
                if (isReflow) {
                    reflowErrorCount++;
                } else {
                    defaultErrorCount++;
                }
            }
        }
    }
}
