import { LazyBootModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "AppBootMsal" */ './lazyFunctions')
);

export const lazyMsalImportProxy = new LazyAction(lazyModule, m => {
    const module = m.lazyModule;
    return module.importModule.bind(module);
});

export const lazyGetAuthTokenForBootFromMsalProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyGetAuthTokenForBootFromMsal;
    return action.importAndExecute.bind(action);
});

export const lazyGetAnchorMailboxProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyGetAnchorMailbox;
    return action.importAndExecute.bind(action);
});

export const lazyCreateMsalPublicClientApplicationProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyCreateMsalPublicClientApplication;
    return action.importAndExecute.bind(action);
});

export const lazyFetchAccessTokenFromMsalProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyFetchAccessTokenFromMsal;
    return action.importAndExecute.bind(action);
});

export const lazyAcquireAccessTokenMsalProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyAcquireAccessTokenMsal;
    return action.importAndExecute.bind(action);
});

export const lazyExecuteMsalNestedAppAuthRequestProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyExecuteMsalNestedAppAuthRequest;
    return action.importAndExecute.bind(action);
});
